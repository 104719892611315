import React from "react";
import Client from "../../comonents/clients/ClientsListLgg";

export default function Clients() {
  return (
    <div className="overflow-hidden h-screen">
      <Client />
    </div>
  );
}
