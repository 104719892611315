import React, { useState } from "react";
import bg from "../assets/ttt.jpg";
import ico from "../assets/icon.png";
import Loading from "../assets/loading.svg";
import { FaEye } from "react-icons/fa";
import { FaEyeLowVision } from "react-icons/fa6";
import { FiUser } from "react-icons/fi";
import { IoLockOpenOutline } from "react-icons/io5";
import Config from "../config/Links";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import bbg from "../assets/bbg.jpg";

const url = Config.G_PERFORM_LINK;

function Login() {
  const [showPass, setShowPasse] = useState(false);
  const [numero, setNumero] = useState("");
  const [pass, setPass] = useState("");
  const [loading, setLoading] = useState(false);
  const [err, setErr] = useState(false);
  const [err1, setErr1] = useState(false);

  // Login Request

  let config = {
    method: "POST",
    url: `${url}/admin/login`,
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
    data: {
      code_telephone: "+228",
      numero_telephone: numero,
      mot_de_passe: pass,
    },
  };

  let config1 = {
    method: "post",
    maxBodyLength: Infinity,
    url: `${url}/connected/to/gescapo`,
  };

  const login = async () => {
    await axios
      .request(config1)
      .then((response) => {
        sessionStorage.setItem("gestoken", response.data.data.access_token);
        sessionStorage.setItem("admin_type", 0);
      })
      .catch((error) => {
        console.log(error);
      });

    await axios
      .request(config)
      .then((response) => {
        if (response.status === 200) {
          sessionStorage.setItem("admin_tk", response.data.token);
          console.log(response.data);
          toast.success("Connexion réussie");
          setTimeout(() => {
            window.location.replace("/dashboard");
          }, 1500);
          setLoading(false);
        }
      })
      .catch((error) => {
        console.log(error);
        if (error.response.status === 404) {
          toast.error(error.response.data.message);
        } else if (error.response.status === 400) {
          toast.error(error.response.data.message);
        } else {
          toast.error("Erreur de connexion !");
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      <div
        style={{ backgroundImage: `url(${bbg})` }}
        className="bg-contain w-full h-screen flex flex-row"
      >
        {/* Left */}
        <div className="max-sm:w-0 max-sm:h-0 max-sm:invisible w-[72%] h-screen bg-transparent flex justify-center items-center"></div>

        {/* Auth */}
        <div className="flex flex-1 rounded-l-xl m-3 max-sm:bg-transparent bg-slate-100 max-sm:bg-white max-sm:h-screen flex-col justify-center items-center">
          <div className="mb-[15%]">
            <img
              src={ico}
              className="w-[160px] h-[40px] rounded-md"
              loading="lazy"
            />
          </div>

          <div className="max-sm:mt-5 w-full mb-20 h-[120px] justify-between px-[50px]">
            {/* Phone */}
            <div>
              <p className="text-black font-bold mb-2">Téléphone</p>
              <div className="bg-white h-12 rounded-md px-1 flex flex-row items-center p-1">
                <FiUser size={25} />
                <input
                  required
                  placeholder="Téléphone"
                  maxLength={8}
                  autoFocus
                  type="text"
                  className="border-none outline-none ml-2 mr-2 font-semibold"
                  value={numero}
                  onChange={(e) => {
                    const input = e.target.value;
                    const filteredInput = input.replace(/[^\d.]/g, "");
                    setNumero(filteredInput);
                  }}
                />
              </div>

              {/* Err */}
              {err ? (
                <div>
                  <p className="text-red-500">Veuillez remplire ce champ !</p>
                </div>
              ) : (
                ""
              )}
            </div>

            {/* Password */}
            <div className="mt-5">
              <p className="text-black font-bold mb-2">Mot de passe</p>
              <div className="bg-white h-12 justify-between rounded-md px-1 flex flex-row items-center p-1">
                <IoLockOpenOutline size={25} />
                <input
                  required
                  placeholder="Mot de passe"
                  type={showPass ? "text" : "password"}
                  className="border-none outline-none w-full ml-2 mr-2 font-semibold"
                  value={pass}
                  onChange={(e) => setPass(e.target.value)}
                />

                <button className="p-1" onClick={() => setShowPasse(!showPass)}>
                  {showPass ? (
                    <FaEyeLowVision size={25} />
                  ) : (
                    <FaEye size={25} />
                  )}
                </button>
              </div>

              {/* Err */}
              {err1 ? (
                <div>
                  <p className="text-red-500">Mot de passe obligatoire !</p>
                </div>
              ) : (
                ""
              )}
            </div>

            {/* Button */}
            <div className="mt-[20%] flex justify-center items-center">
              {loading ? (
                <img src={Loading} className="w-8 h-8" />
              ) : (
                <button
                  onClick={() => {
                    if (numero.length < 1) {
                      setLoading(false);
                      setErr(true);
                    } else if (pass.length < 1) {
                      setLoading(false);
                      setErr1(true);
                    } else if (numero.length > 1 && pass.length > 1) {
                      setErr(false);
                      setErr1(false);
                    }

                    login();
                    setLoading(!loading);
                  }}
                  type="submit"
                  className={
                    "bg-blue-600 text-white w-4/5 p-1 h-[50px] hover:opacity-40 rounded-xl font-bold"
                  }
                >
                  Se connecter
                </button>
              )}
            </div>

            {/* Copy */}
            <div className="mt-[60px] flex justify-center items-center">
              <p className="flex text-center">
                Tous droits réservés © {new Date().getFullYear()}, Copyright by
                GESCAPRO
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* Toastify */}
      <ToastContainer />
    </>
  );
}

export default Login;
