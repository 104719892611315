import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import moment from "moment";
import "moment/locale/fr";
import { PiFilePdfDuotone } from "react-icons/pi";
import { IoArrowBack } from "react-icons/io5";
import Config from "../../config/Links";
import axios from "axios";
import Loading from "./../../assets/rff.svg";

const url = Config.G_PERFORM_LINK;
const geSUrl = Config.GESCAPO_LINK;
const gestToken = sessionStorage.getItem("gestoken");
const token = sessionStorage.getItem("admin_tk");

const menu = [
  { id: 0, title: "Tous" },
  { id: 1, title: `Mois de ${moment().subtract(1, "month").format("MMMM")}` },
  { id: 2, title: "En cours" },
];

function ColPayDetail() {
  const navigation = useNavigate();
  const [loader, setLoader] = React.useState(false);
  moment.locale("fr");
  const { id } = useParams();
  const [colFilter, setColFilter] = useState([]);
  const [salaireColecteurEnCours, setSalaireColecteurEnCours] = useState([]);
  const [select, setSelect] = useState(0);
  const [compInfo, setCompInfo] = useState({});
  const [loading, setLoding] = useState(true);

  function getMonthName(monthNumber) {
    const months = [
      "Janvier",
      "Février",
      "Mars",
      "Avril",
      "Mai",
      "Juin",
      "Juillet",
      "Août",
      "Septembre",
      "Octobre",
      "Novembre",
      "Décembre",
    ];
    return months[monthNumber];
  }

  // Collector List
  useEffect(() => {
    try {
      const ctrLength = async () => {
        const token = sessionStorage.getItem("gestoken");
        const response = await axios.post(
          `${geSUrl}/api/auth/admin/agent_collecteur/list?all=true`,
          {},
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
        if (response.status === 200) {
          setColFilter(response.data.data);
        }
      };
      ctrLength();
    } catch (error) {
      console.log(error);
    }
  }, [id]);

  // Salaire Colecteur en cours
  // useEffect(() => {
  //   try {
  //     const ctrLength = async () => {
  //       const token = sessionStorage.getItem("admin_tk");
  //       const response = await axios.get(
  //         `${url}/v1/get/all/col/comission/${id}`,
  //         {
  //           headers: { Authorization: `Bearer ${token}` },
  //         }
  //       );
  //       if (response.status === 200) {
  //         console.log(response);
  //         return setSalaireColecteurEnCours(response.data.data);
  //       }
  //     };
  //     ctrLength();
  //   } catch (error) {
  //     console.log(error);
  //   }
  // }, []);
  // Statistique Depot de paiement

  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: `${geSUrl}/api/auth/admin/transaction/collecteur/${id}`,
    headers: {
      Authorization: `Bearer ${gestToken}`,
    },
  };

  useEffect(() => {
    const fetchMout = async () => {
      await axios
        .request(config)
        .then((response) => {
          setSalaireColecteurEnCours(response.data.data);
          console.log("Salaire en cours :", response.data.data);
          setLoding(false);
        })
        .catch((error) => {
          console.log(error);
          setLoding(false);
        });
    };
    fetchMout();
  }, []);

  // Company Pourcent APPLICATION
  let config3 = {
    method: "get",
    maxBodyLength: Infinity,
    url: `${url}/v1/get/company/info`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  useEffect(() => {
    const fetchCompany = async () => {
      await axios
        .request(config3)
        .then((response) => {
          setCompInfo(response.data.companyInfo);
        })
        .catch((error) => {
          console.log(error);
        });
    };
    fetchCompany();
  }, []);

  //   Dowload to pdf
  const downloadPDF = () => {
    const capture = document.getElementById("mouth_salary");
    setLoader(true);
    html2canvas(capture).then((canvas) => {
      const imgData = canvas.toDataURL("img/png", 2000);
      const doc = new jsPDF(
        salaireColecteurEnCours.length > 10 ? "p" : "l",
        "mm",
        salaireColecteurEnCours.length > 10 ? "A6" : "A5",
        true
      );
      const componentWidth = doc.internal.pageSize.getWidth();
      const componentHeight = doc.internal.pageSize.getHeight();
      doc.addImage(imgData, "PNG", 0, 0, componentWidth, componentHeight);
      setLoader(false);
      doc.save(
        `Paiement_${
          getMonthName(new Date().getMonth()) + "_" + new Date().getFullYear()
        }.pdf`
      );
    });
  };

  const previousMonthStart = moment().subtract(1, "month").startOf("month");
  const previousMonthEnd = moment().subtract(1, "month").endOf("month");

  const [totalDevers, setTotalDevers] = useState(0);
  const [totalCommission, setTotalCommission] = useState(0);
  const currentDate = moment();
  const currentYear = currentDate.year();
  const currentMonth = moment().month();

  // Update totalCommission whenever salaireColecteurEnCours or select changes
  useEffect(() => {
    if (salaireColecteurEnCours && salaireColecteurEnCours.length > 0) {
      let deversementTotal = 0;
      let commissionTotal = 0;

      salaireColecteurEnCours.forEach((item) => {
        const itemDate = moment(item.created_at);

        // Calculate commission based on select value
        if (item.type === 0) {
          let itemMontant = parseFloat(item.montant);
          let itemCommission =
            (parseFloat(compInfo.payment_pourcent) / 100) * itemMontant;

          // Calculate deversementTotal based on select value
          if (select === 0) {
            deversementTotal += itemMontant;
            commissionTotal += itemCommission;
          } else if (
            select === 1 &&
            itemDate.isBetween(
              previousMonthStart,
              previousMonthEnd,
              undefined,
              "[]"
            )
          ) {
            deversementTotal += itemMontant;
            commissionTotal += itemCommission;
          } else if (
            select === 2 &&
            itemDate.year() === currentYear &&
            itemDate.month() === currentMonth &&
            itemDate.date() >= 1 &&
            itemDate.date() <= 30
          ) {
            deversementTotal += itemMontant;
            commissionTotal += itemCommission;
          }
        }
      });

      setTotalDevers(deversementTotal);
      setTotalCommission(commissionTotal);
    } else {
      setTotalDevers(0);
      setTotalCommission(0);
    }
  }, [salaireColecteurEnCours, select, compInfo]);

  return (
    <>
      <div className="max-sm:py-[10%]">
        {/* Header */}
        <div className="mt-[5%] flex flex-row items-center justify-between px-10">
          {/* Date */}
          <div className="flex flex-row items-center">
            {/* Searchbar */}
            <button
              onClick={() => navigation(-1)}
              className="bg-[#ADADAD36] p-3 rounded-md hover:bg-slate-500"
            >
              <IoArrowBack color="white" />
            </button>

            <p className="text-white text-lg ml-2">
              Analyse {getMonthName(new Date().getMonth())}{" "}
              {new Date().getFullYear()}
            </p>
          </div>

          {/* MENU */}
          <div className="flex flex-row rounded-md bg-[#ADADAD36] p-2 items-center justify-between gap-5">
            {menu.map((item) => (
              <button
                onClick={() => setSelect(item.id)}
                key={item.id}
                className={
                  select === item.id
                    ? "p-2 rounded-md bg-blue-800"
                    : "p-2 rounded-md"
                }
              >
                <p className="text-white">{item.title}</p>
              </button>
            ))}
          </div>

          {/* DownLoad */}
          <button
            onClick={downloadPDF}
            disabled={!(loader === false)}
            className="bg-[#ADADAD36] rounded-full p-2 flex flex-row items-center hover:bg-gray-700"
          >
            <p className="text-white">Télécharger</p>
            <PiFilePdfDuotone className="text-white ml-1" size={25} />
          </button>
        </div>

        {/* Body */}
        <div
          className={
            salaireColecteurEnCours.length > 8
              ? "overflow-hidden scrollbar-hide h-full px-[130px] max-sm:px-0 pb-5"
              : "overflow-hidden scrollbar-hide h-[85vh] px-[130px] max-sm:px-0 pb-5"
          }
        >
          <div
            className="flex flex-1 flex-col overflow-auto scrollbar-hide h-full rounded-md bg-white p-2"
            id="mouth_salary"
          >
            {/* header */}
            <div className="flex flex-row justify-between">
              {/* Date */}
              <div className="flex flex-col">
                <p className="text-black text-xl font-black">
                  {
                    salaireColecteurEnCours.find(
                      (ee) => ee.collecteurs.id.toString() === id
                    )?.collecteurs.nom
                  }{" "}
                  {
                    salaireColecteurEnCours.find(
                      (ee) => ee.collecteurs.id.toString() === id
                    )?.collecteurs.prenom
                  }
                </p>
                <p>Deversement : {totalDevers.toLocaleString("fr-FR")} F</p>
              </div>

              {/* Collecteur Info */}
              <div className="text-red-600">
                <p>Gains : {totalCommission.toLocaleString("fr-FR")} F</p>
              </div>
            </div>

            {/* Body */}
            <div>
              {/* Title */}
              <p className="font-bold text-black text-xl text-center">
                <u>
                  {select === 0
                    ? "Tous les paiements "
                    : select === 1
                    ? `Paiement du mois de ${moment()
                        .subtract(1, "month")
                        .format("MMMM")}`
                    : select === 2 && "Paiement en cours"}
                </u>
              </p>

              {/* Filtrage des Salaires */}
              {select === 0 ? (
                loading ? (
                  <div className="flex h-full justify-center items-center">
                    <img className="w-12 h-12 mt-[40px]" src={Loading} />
                  </div>
                ) : salaireColecteurEnCours.length > 0 ? (
                  <div>
                    <p>
                      {salaireColecteurEnCours &&
                        [...salaireColecteurEnCours].reverse().map((item) => {
                          const itemDate = moment(item.created_at);
                          if (item.type === 0) {
                            return (
                              <div
                                key={item.id}
                                className="flex flex-row items-center justify-between p-2 my-1"
                              >
                                {/* Right */}
                                <div className="flex flex-row items-center">
                                  <img
                                    src={require("../../assets/pay.png")}
                                    className="w-10 h-10"
                                  />

                                  {/* Center */}
                                  <div className="ml-5">
                                    <p className="text-black">
                                      Montant deverser : {item.montant} FCFA
                                    </p>
                                    <p className="text-black">
                                      Commission payer :{" "}
                                      {(
                                        (compInfo.payment_pourcent / 100) *
                                        item.montant
                                      ).toFixed(0)}{" "}
                                      FCFA
                                    </p>
                                  </div>
                                </div>

                                {/* Left */}
                                <p className="text-black">
                                  Date : {itemDate.calendar()}
                                </p>
                              </div>
                            );
                          }
                        })}
                    </p>
                  </div>
                ) : (
                  <div className="flex h-full justify-center items-center">
                    <p>Aucun paiement trouvé !</p>
                  </div>
                )
              ) : select === 1 ? (
                loading ? (
                  <div className="flex h-full justify-center items-center">
                    <img className="w-12 h-12 mt-[40px]" src={Loading} />
                  </div>
                ) : salaireColecteurEnCours.length > 0 ? (
                  <div>
                    <p>
                      {salaireColecteurEnCours &&
                        [...salaireColecteurEnCours].reverse().map((item) => {
                          const itemDate = moment(item.created_at);

                          if (
                            item.type === 0 &&
                            itemDate.isBetween(
                              previousMonthStart,
                              previousMonthEnd,
                              undefined,
                              "[]"
                            )
                          ) {
                            return (
                              <div
                                key={item.id}
                                className="flex flex-row items-center justify-between p-2 my-1"
                              >
                                {/* Right */}
                                <div className="flex flex-row items-center">
                                  <img
                                    src={require("../../assets/pay.png")}
                                    className="w-10 h-10"
                                  />

                                  {/* Center */}
                                  <div className="ml-5">
                                    <p className="text-black">
                                      Montant deverser : {item.montant} FCFA
                                    </p>
                                    <p className="text-black">
                                      Commission payer :{" "}
                                      {(
                                        (compInfo.payment_pourcent / 100) *
                                        item.montant
                                      ).toFixed(0)}{" "}
                                      FCFA
                                    </p>
                                  </div>
                                </div>

                                {/* Left */}
                                <p className="text-black">
                                  Date : {itemDate.calendar()}
                                </p>
                              </div>
                            );
                          }
                        })}
                    </p>
                  </div>
                ) : (
                  <div className="flex h-full justify-center items-center">
                    <p>Aucun paiement trouvé !</p>
                  </div>
                )
              ) : (
                select === 2 &&
                (loading ? (
                  <div className="flex h-full justify-center items-center">
                    <img className="w-12 h-12 mt-[40px]" src={Loading} />
                  </div>
                ) : salaireColecteurEnCours.length > 0 ? (
                  <div>
                    <p>
                      {salaireColecteurEnCours &&
                        [...salaireColecteurEnCours].reverse().map((item) => {
                          const itemDate = moment(item.created_at);
                          const currentDate = moment();
                          const currentYear = currentDate.year();
                          const currentMonth = currentDate.month();

                          if (
                            item.type === 0 &&
                            itemDate.year() === currentYear &&
                            itemDate.month() === currentMonth &&
                            itemDate.date() >= 1 &&
                            itemDate.date() <= 30
                          ) {
                            return (
                              <div
                                key={item.id}
                                className="flex flex-row items-center justify-between p-2 my-1"
                              >
                                {/* Right */}
                                <div className="flex flex-row items-center">
                                  <img
                                    src={require("../../assets/pay.png")}
                                    className="w-10 h-10"
                                  />

                                  {/* Center */}
                                  <div className="ml-5">
                                    <p className="text-black">
                                      Montant deverser : {item.montant} FCFA
                                    </p>
                                    <p className="text-black">
                                      Commission payer :{" "}
                                      {(
                                        (compInfo.payment_pourcent / 100) *
                                        item.montant
                                      ).toFixed(0)}{" "}
                                      FCFA
                                    </p>
                                  </div>
                                </div>

                                {/* Left */}
                                <p className="text-black">
                                  Date : {itemDate.calendar()}
                                </p>
                              </div>
                            );
                          }
                        })}
                    </p>
                  </div>
                ) : (
                  <div className="flex h-full justify-center items-center">
                    <p>Aucun paiement trouvé !</p>
                  </div>
                ))
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ColPayDetail;
