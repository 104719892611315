import React, { useEffect, useState } from "react";
import axios from "axios";
import Loading from "../../assets/rff.svg";
import Nothing from "../../assets/rien.png";
import Task from "../../assets/task.png";
import Config from "../../config/Links";
import { FaUserPlus } from "react-icons/fa6";
import { FaTasks } from "react-icons/fa";
import { CiEdit } from "react-icons/ci";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { CiSearch } from "react-icons/ci";
import { HiOutlineArchive } from "react-icons/hi";
import moment from "moment";
import "moment/locale/fr";
import { Link } from "react-router-dom";

function TasksList() {
  moment.locale("fr");
  const [task, setTask] = useState([]);
  const [loading, setLoading] = useState(true);
  const [search, setSearch] = useState("");
  const url = Config.G_PERFORM_LINK;
  const token = sessionStorage.getItem("admin_tk");
  const [showModal, setShowModal] = useState(false);
  const [taskID, setTaskID] = useState("");

  // Request
  let config = {
    method: "get",
    maxBodyLength: Infinity,
    url: `${url}/v1/admin/get/all/tasks?query=${search}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  useEffect(() => {
    const fetchTasks = async () => {
      try {
        const response = await axios.request(config);
        setTask(response.data.data);
        setLoading(false);
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    };
    fetchTasks();
  }, [search]);

  // Archive Tasks
  let config2 = {
    method: "delete",
    maxBodyLength: Infinity,
    url: `${url}/v1/admin/delete/task/${taskID}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const archiveTask = async () => {
    await axios
      .request(config2)
      .then((response) => {
        toast.success(response.data.message);
        setTimeout(() => {
          setShowModal(!showModal);
          window.location.reload();
        }, 1000);
      })
      .catch((error) => {
        toast.error(error.response.data.message);
        console.log(error);
      });
  };

  return (
    <>
      <div className="py-[60px] w-full flex-col  max-sm:px-2">
        {/* Search Bar */}
        <div className="w-full mb-2 flex flex-row items-center justify-between px-3 max-sm:px-0">
          {/* Add NEW Tasks */}
          
          <div className="flex flex-row w-[300px] justify-between items-center">
            {/* Create Task */}
            <Link to="/dashboard/create/task">
              <button
                title="Ajouter"
                className=" bg-[#ADADAD36] max-sm:w-[150px] max-sm:h-10 hover:text-slate-700 rounded-full flex-wrap items-center p-2 max-sm:p-1 flex flex-row"
              >
                <FaTasks size={20} color="white" />
                <p className="text-white ml-2 text-xs">Ajouter une tâche</p>
              </button>
            </Link>

            {/* Archive Task */}
            <Link to="/dashboard/task/archives">
              <button
                title="Ajouter"
                className=" bg-[#ADADAD36] max-sm:w-[150px] max-sm:h-10 hover:text-slate-700 rounded-full flex-wrap items-center p-2 max-sm:p-1 flex flex-row"
              >
                <HiOutlineArchive size={20} color="white" />
                <p className="text-white ml-2 text-xs">Tâches archiver</p>
              </button>
            </Link>
          </div>

          {/* Searchbar */}
          <div
            title="Recherche"
            className="bg-[#ADADAD36] w-[300px] max-sm:w-[auto] p-2 flex flex-row items-center"
          >
            <CiSearch size={25} color="white" />
            <input
              type="search"
              placeholder="Filtrée vos tâches"
              className="border-none outline-none bg-transparent w-full h-full text-white ml-2"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
          </div>
        </div>

        {/* Container */}
        <div className="flex flex-row flex-wrap max-h-[85vh] pb-[10%] overflow-auto scrollbar-hide">
          {loading ? (
            <div className="w-full h-[85vh] mb-[70%] max-sm:mb-[50%] flex flex-grow justify-center items-center">
              <img src={Loading} className="w-20 h-20" />
            </div>
          ) : task.length > 0 ? (
            task.map(
              ({
                id,
                title,
                description,
                start_date,
                end_date,
                status,
                created_date,
              }) => (
                <div
                  title="Tâches"
                  key={id}
                  className="bg-slate-700 w-[320px] max-sm:w-full my-2 h-[160px] rounded-md justify-between px-2 mx-2 flex flex-row hover:bg-[#282828] cursor-pointer"
                >
                  <div className="p-2 w-20 h-20">
                    <img src={Task} className="w-10 h-10" />
                  </div>

                  <div className="flex flex-col justify-around">
                    <p className="text-white font-bold text-ld">
                      {title.length > 20 ? `${title.slice(0, 20)}...` : title}
                    </p>
                    <p className="text-white flex-wrap">
                      Tâches : {description}
                    </p>
                    <p className="text-white">
                      Fin : {moment(end_date).calendar()}
                    </p>

                    <div className="flex flex-row items-center w-full">
                      <p className="text-white">Status : </p>
                      <p
                        className={
                          status === "En cours..."
                            ? "text-green-600"
                            : "text-red-600"
                        }
                      >
                        {status}
                      </p>
                    </div>

                    <p className="text-white">
                      Début : {moment(start_date).calendar()}
                    </p>
                  </div>

                  {/* Tools */}
                  <div className="flex flex-col justify-start py-2">
                    {/* <button title="Editer">
                      <CiEdit size={20} color="white" />
                    </button> */}

                    <button
                      onClick={() => {
                        setTaskID(id);
                        setShowModal(!showModal);
                      }}
                      title="Archiver"
                    >
                      <HiOutlineArchive size={25} className="text-blue-500" />
                    </button>
                  </div>
                </div>
              )
            )
          ) : (
            <div className="flex w-full justify-center items-center h-[85vh]">
              <div className="flex flex-col justify-center items-center mb-[10%] max-sm:mb-[50%]">
                <img src={Nothing} className="w-20 h-20 mb-4" />
                <p className="font-bold text-white mb-5">
                  Aucun tâche trouver !
                </p>

                {/* Add NEW Controleur */}
                <Link to="/dashboard/add/controleur">
                  <button className="bg-[#ADADAD36] hover:text-slate-700 rounded-full items-center p-2 flex flex-row">
                    <FaUserPlus size={20} color="white" />
                    <p className="text-white ml-2">Ajouter une tâche</p>
                  </button>
                </Link>
              </div>
            </div>
          )}
        </div>
      </div>

      {/* Confirm Pop */}
      <div
        className={
          !showModal
            ? "hidden"
            : "flex w-9/12 absolute top-0 bg-transparent h-full justify-center items-center"
        }
      >
        <div
          className={
            !showModal
              ? "hidden"
              : "absolute w-[340px] h-[240px] flex flex-col max-sm:w-full rounded-xl justify-center bg-gray-900 top-[25%]"
          }
        >
          <div className="flex absolute top-4 items-start justify-center w-full">
            <div className="p-3 rounded-full bg-[#F1000029]">
              <HiOutlineArchive size={30} color="red" />
            </div>
          </div>

          {/* Title */}
          <div className="mb-[50px]">
            <p className="text-white font-bold text-xl text-center">
              Archiver cette tâche la désactive ?
            </p>
          </div>

          <div className="px-[10%] w-full absolute flex justify-between bottom-5">
            {/* yes  */}
            <button
              onClick={() => {
                archiveTask();
                setTimeout(() => {
                  window.location.reload();
                }, 1000);
              }}
              className="w-full h-10 bg-red-600 rounded-md hover:bg-slate-600 mx-2"
            >
              <p className="text-white font-medium text-base">Oui</p>
            </button>

            {/* Not  */}
            <button
              onClick={() => {
                setShowModal(false);
              }}
              className="w-full h-10 bg-blue-600 rounded-md hover:bg-slate-600 mx-2"
            >
              <p className="text-white font-medium text-base">Non</p>
            </button>
          </div>
        </div>
      </div>

      <ToastContainer />
    </>
  );
}

export default TasksList;
