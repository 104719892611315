import React from "react";
import CarnetList from "../../components/carnet/CarnetList";

function Carnet() {
  return (
    <div className="px-3 overflow-hidden h-screen">
      <CarnetList />
    </div>
  );
}

export default Carnet;