import React from "react";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import { Icon } from "leaflet";
import "leaflet/dist/leaflet.css";

function GeoLocate({ longitt, latitt, client_name }) {
  const markerICON = new Icon({
    iconUrl: require("../../../assets/location.png"),
    iconSize: [35, 35],
  });

  return (
    <MapContainer
      center={[latitt, longitt]}
      zoom={12}
      scrollWheelZoom={false}
      className="rounded-xl w-[400px] h-[400px]"
    >
      <TileLayer
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      <Marker icon={markerICON} position={[latitt, longitt]}>
        <Popup>
          <p>Position du client {client_name}</p>
        </Popup>
      </Marker>
    </MapContainer>
  );
}

export default GeoLocate;
