import React, { useEffect, useState } from "react";
import axios from "axios";
import Config from "../../config/Links";
import Loading from "../../assets/loading.svg";
import Refresh from "../../assets/rff.svg";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import qs from "qs";
import { FiUsers } from "react-icons/fi";
import { FaTasks } from "react-icons/fa";
import { PiBuildingOffice } from "react-icons/pi";
import { IoIosArrowDown } from "react-icons/io";
import { useNavigate } from "react-router-dom";

function CreateControleur() {
  const navigation = useNavigate();
  const url = Config.G_PERFORM_LINK;
  const token = sessionStorage.getItem("admin_tk");

  const [open, setOpen] = useState(false);
  const [cId, setCId] = useState(null);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [intervalle_date, setIntervalle_date] = useState("");
  const [intervalle_date1, setIntervalle_date1] = useState("");
  const [controleurs, setControleurs] = useState([]);

  const [select, setSelect] = useState(null);
  const [selectName, setSelectName] = useState("");
  const [loading, setLoading] = useState(true);
  const [loading1, setLoading1] = useState();

  // Controleur List
  let config = {
    method: "get",
    maxBodyLength: Infinity,
    url: `${url}/v1/admin/search/controleur?query`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  useEffect(() => {
    const fetchControleurList = async () => {
      await axios
        .request(config)
        .then((response) => {
          setControleurs(response.data.data);
          console.log(response.data.data);
          setLoading(false);
        })
        .catch((error) => {
          console.log(error);
        });
    };
    fetchControleurList();
  }, []);

  // Send Request
  let data = qs.stringify({
    title: title,
    description: description,
    start_date: intervalle_date,
    end_date: intervalle_date1,
  });

  let config2 = {
    method: "post",
    maxBodyLength: Infinity,
    url: `${url}/v1/admin/create/tasks/${cId}`,
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      Authorization: `Bearer ${token}`,
    },
    data: data,
  };

  const createTask = async () => {
    await axios
      .request(config2)
      .then((response) => {
        if (response.status === 201) {
          toast.success(response.data.message);
          setTimeout(() => {
            setLoading1(false);
            navigation(-1);
          }, 1500);
        }
      })
      .catch((error) => {
        console.log(error);
        if (error.response.status === 400) {
          toast.error(error.response.data.message);
          setLoading1(false);
        } else if (error.response.status === 403) {
          toast.error(error.response.data.message);
          setLoading1(false);
        } else {
          toast.error("Erreur de creation de tâche !");
          console.log(error);
          setLoading1(false);
        }
      })
      .finally(() => {
        setLoading1(false);
      });
  };

  return (
    <>
      <div
        onClickCapture={() => setOpen(false)}
        className="flex justify-center items-center mt-[70px] px-[30%] max-sm:px-0"
      >
        <div className="flex w-full h-[90vh] bg-[#ADADAD36] rounded-xl max-sm:bg-transparent flex-col">
          {/* Header */}
          <div className="flex flex-row items-center w-full justify-center">
            {/* Add New Button */}
            <div className="flex flex-row h-20 items-center px-3">
              <FaTasks size={30} color="#fff" />
              <p className="text-white text-xl font-bold ml-3">
                Ajouter une tâche
              </p>
            </div>
          </div>

          {/* Forms */}
          <div className="px-[20%] max-sm:px-[10%]">
            {/* Title */}
            <div className="bg-[#000000] max-sm:bg-slate-500 flex flex-row items-center p-3 rounded-lg mb-4">
              <FaTasks size={30} color="#fff" />
              <input
                required
                type="text"
                placeholder="Titre"
                className="bg-transparent ml-3 h-full w-full text-white border-none outline-none"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
              />
            </div>

            {/* DESC */}
            <div className="bg-[#000000] max-sm:bg-slate-500 flex flex-row items-center p-3 rounded-lg mb-4">
              <FaTasks size={30} color="#fff" />
              <input
                required
                type="text"
                placeholder="Description"
                className="bg-transparent ml-3 h-full w-full text-white border-none outline-none"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              />
            </div>

            {/* Intervalle date */}
            <p className="text-white mb-1">Début</p>
            <div className="bg-[#000000] max-sm:bg-slate-500 flex flex-row items-center p-3 rounded-lg mb-4">
              <FaTasks size={30} color="#fff" />
              <input
                required
                type="date"
                className="bg-transparent ml-3 h-full w-full text-white border-none outline-none"
                value={intervalle_date}
                onChange={(e) => setIntervalle_date(e.target.value)}
              />{" "}
            </div>

            <p className="text-white mb-1">Fin</p>
            <div className="bg-[#000000] max-sm:bg-slate-500 flex flex-row items-center p-3 rounded-lg mb-4">
              <FaTasks size={30} color="#fff" />
              <input
                required
                type="date"
                className="bg-transparent ml-3 h-full w-full text-white border-none outline-none"
                value={intervalle_date1}
                onChange={(e) => setIntervalle_date1(e.target.value)}
              />{" "}
            </div>

            {/* Controleur List */}
            <button
              onClick={() => setOpen(!open)}
              className="bg-[#000000] max-sm:bg-slate-500 flex flex-row items-center p-3 rounded-lg mb-4 w-full justify-between"
            >
              <div className="flex justify-between items-center">
                <FiUsers size={30} color="#fff" />
                <p className="text-white ml-3">
                  {selectName || "Selectionner un contrôleur"}
                </p>
              </div>

              <IoIosArrowDown size={20} color="#fff" />
            </button>

            {/* Creat Task button */}
            <div className="flex justify-center items-center mt-10">
              {loading1 ? (
                <img src={Loading} className="w-10 h-10" />
              ) : (
                <button
                  onClick={() => {
                    setLoading1(!loading1);
                    createTask();
                  }}
                  disabled={
                    title === "" ||
                    description === "" ||
                    intervalle_date === "" ||
                    cId === null
                  }
                  className={
                    title === "" ||
                    description === "" ||
                    intervalle_date === "" ||
                    cId === null
                      ? "bg-blue-900 p-3 w-[300px] rounded-xl opacity-60"
                      : "bg-blue-900 p-3 w-[300px] rounded-xl hover:bg-slate-900"
                  }
                >
                  <p className="text-white">Lancé la tâche</p>
                </button>
              )}
            </div>
          </div>
        </div>

        {/* Selected Controleur */}
        <div
          className={
            !open
              ? "hidden transition-all"
              : "absolute w-[250px] flex flex-col  items-center bg-black max-sm:bg-slate-500 rounded-xl max-h-[250px] max-sm:h-[200px] mb-9 max-sm:mb-20 bottom-20 transition"
          }
        >
          <div className="flex flex-row items-center h-[30px]">
            <FiUsers size={20} color="#fff" className="mt-2" />
            <p className="text-white text-lg font-semibold ml-3 mt-2">
              List des Contrôleurs
            </p>
          </div>

          {/* Divider */}
          <div className="w-full h-[1px] bg-slate-300 my-2" />

          {/* Body */}
          <div className="overflow-scroll flex flex-col">
            {loading ? (
              <div className="w-full h-full flex flex-col justify-center items-center p-3">
                <img src={Refresh} className="w-12 h-12" />
                <p className="text-white">Recherche Contrôleur...</p>
              </div>
            ) : (
              controleurs.length > 0 &&
              controleurs &&
              controleurs.map(({ id, nom, agence_name, numero_telephone }) => (
                <button
                  key={id}
                  onClick={() => {
                    setSelect(id);
                    setCId(id);
                    setSelectName(nom);
                    setOpen(false);
                  }}
                  className={
                    select === id
                      ? "bg-slate-700 p-2"
                      : "hover:bg-slate-700 rounded-md p-2"
                  }
                >
                  <p className="text-white">{nom}</p>
                  <p className="text-white">{agence_name}</p>
                </button>
              ))
            )}
          </div>
        </div>

        {/* Toastify */}
        <ToastContainer />
      </div>
    </>
  );
}

export default CreateControleur;
