import React from 'react'
import Stores from "../../components/store/StoreProductList"

export default function Store() {
  return (
    <div>
      <Stores />
    </div>
  )
}
