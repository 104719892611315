import React from 'react'

function Clients() {
  return (
    <div className="w-full h-screen flex items-center justify-center">
      <p className="text-white font-bold text-lg">Clients</p>
    </div>
  )
}

export default Clients
