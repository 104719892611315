import React, { useEffect, useState } from "react";
import Load from "../../../assets/rff.svg";
import Nothing from "../../../assets/rien.png";
import Config from "../../config/Links";
import { CiSearch } from "react-icons/ci";
import { CiShoppingCart } from "react-icons/ci";
import { FaPencilAlt } from "react-icons/fa";
import { IoTrashBinOutline } from "react-icons/io5";
import { IoMdClose } from "react-icons/io";
import { PiCardsThreeLight } from "react-icons/pi";
import { CiFilter } from "react-icons/ci";
import { IoIosTimer } from "react-icons/io";
import { PiCardsThreeThin } from "react-icons/pi";
import { MdOutlineShoppingCart } from "react-icons/md";
import { IoMdCheckmarkCircleOutline } from "react-icons/io";
import { IoMdArrowDropdown } from "react-icons/io";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment";
import "moment/locale/fr";
import axios from "axios";
import qs from "qs";

// List Type
const listType = [
  {
    id: 0,
    title: "Carnets",
    ico: <MdOutlineShoppingCart className="text-white" size={25} />,
  },
  {
    id: 1,
    title: "Produits",
    ico: <PiCardsThreeThin className="text-white" size={25} />,
  },
  {
    id: 2,
    title: "Validités",
    ico: <IoIosTimer className="text-white" size={25} />,
  },
];

// Advance Search
export default function CarnetsListLgg() {
  moment.locale("fr");
  // State
  const [listKey, setListeKey] = useState(0);
  const [listKeyShow, setListKeyShow] = useState(false);
  const [productList, setProductList] = useState([]);
  const [productID, setProductID] = useState("");
  const [carnetList, setCarnetList] = useState([]);
  const [validiteList, setValiditeList] = useState([]);
  const [valModel, setValModel] = useState([]);
  const [nameSearch, setNameSearch] = useState("");
  const [validiteSearch, setValiditeSearch] = useState("");
  const [loading, setLoading] = useState(true);
  const [select, setSelect] = useState([]);
  const [notFound, setNotFound] = useState(false);
  const [modal1, setModal1] = useState(false);
  const [modal2, setModal2] = useState(false);
  const [modal3, setModal3] = useState(false);
  const [modal4, setModal4] = useState(false);
  const [modal5, setModal5] = useState(false);
  const [modal6, setModal6] = useState(false);
  const [modal7, setModal7] = useState(false);
  const [modal8, setModal8] = useState(false);
  const [modal9, setModal9] = useState(false);

  // Popup States
  const [sValListe, setvalListe] = useState(false);

  // Inseret Data State
  const [carnetName, setCarnetName] = useState("");
  const [carnetPrice, setCarnetPrice] = useState("");
  const [valDay, setValDay] = useState("");
  const [valSelect, setValSelect] = useState([]);
  const [valDetails, setValDetails] = useState({});
  const [currentCarnet, setCurrentCarnet] = useState(null);

  // Create Product
  const [carnetID, setCarnetID] = useState("");
  const [carnetSName, setCarnetSName] = useState("");
  const [valID, setValID] = useState([]);
  const [valName, setValName] = useState("");
  const [valExpire, setValExpire] = useState("");
  const [commission, setCommission] = useState([]);
  const [prixAchat, setPrixAchat] = useState(0);
  const [mise, setMise] = useState([]);

  // Get All validite Modele
  const fetchAllValiditeModel = async () => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${Config.G_PERFORM_LINK}/v2/get/all/validite/model`,
      headers: {},
    };

    await axios
      .request(config)
      .then((response) => {
        setValiditeList(response.data.validite_models);
        setValModel(response.data.validite_models);
        console.log(response.data.validite_models);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // Create carnet
  const createNewCarnet = async () => {
    const token = sessionStorage.getItem("admin_tk");

    if (!token) {
      toast.error("Token not found");
      return;
    }

    let data = qs.stringify({
      nom: carnetName,
      prix_achat: carnetPrice,
    });

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${Config.G_PERFORM_LINK}/v2/create/lgg/carnet`,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: `Bearer ${token}`,
      },
      data: data,
    };

    try {
      const response = await axios.request(config);
      if (response.status === 201) {
        setModal1(false);
        toast.success(response.data.message);
        window.location.reload();
      }
    } catch (error) {
      const errorMessage = error.response?.data?.message || "An error occurred";
      toast.error(errorMessage);
      console.log(error);
    }
  };

  // Get All Carnets Liste
  useEffect(() => {
    const fetchAllCarnetList = async () => {
      const token = sessionStorage.getItem("admin_tk");
      let config = {
        method: "get",
        maxBodyLength: Infinity,
        url: `${Config.G_PERFORM_LINK}/v2/get/all/carnet/liste`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      axios
        .request(config)
        .then((response) => {
          setCarnetList(response.data.data);
        })
        .catch((error) => {
          console.log(error);
        });
    };
    fetchAllCarnetList();
  }, []);

  // Create New Validité
  const createVal = async () => {
    const token = sessionStorage.getItem("admin_tk");
    let data = qs.stringify({
      nom: "Jours",
      temp_expiration: valDay,
    });

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${Config.G_PERFORM_LINK}/v2/create/validite/model`,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: `Bearer ${token}`,
      },
      data: data,
    };

    await axios
      .request(config)
      .then((response) => {
        if (response.status === 201) {
          setModal3(false);
          toast.success(response.data.message);
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        }
      })
      .catch((error) => {
        toast.error(error.response.data.message);
        console.log(error);
      });
  };

  // Get All Validite Liste
  useEffect(() => {
    const fetchAllValiditeModel = async () => {
      let config = {
        method: "get",
        maxBodyLength: Infinity,
        url: `${Config.G_PERFORM_LINK}/v2/get/all/validite/model`,
        headers: {},
      };

      await axios
        .request(config)
        .then((response) => {
          setValiditeList(response.data.validite_models);
        })
        .catch((error) => {
          console.log(error);
        });
    };
    fetchAllValiditeModel();
  }, []);

  // Create Product
  const createProduct = async () => {
    const token = sessionStorage.getItem("admin_tk");
    const qs = require("qs");

    for (let i = 0; i < valSelect.length; i++) {
      const tempExpiration = valSelect[i];
      const details = valDetails[tempExpiration];

      if (!details) {
        console.warn(`Details not found for ${tempExpiration}`);
        continue;
      }

      let data = qs.stringify({
        validite_model_id: valID[i], // Utilisez l'index i pour obtenir l'ID correspondant
        commission: details.commission,
        prix_achat: prixAchat,
        mise: details.mise,
      });

      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `${Config.G_PERFORM_LINK}/v2/add/new/${carnetID}/validite`,
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: `Bearer ${token}`,
        },
        data: data,
      };

      try {
        const response = await axios.request(config);
        toast.success(response.data.message);
      } catch (error) {
        toast.error(error.response.data.message);
        console.log(error);
      }
    }

    // Rafraîchir la page après la création de tous les produits
    setTimeout(() => {
      window.location.reload();
    }, 1500);
  };

  // Get All product Liste
  const fetchAllProduct = async () => {
    const token = sessionStorage.getItem("admin_tk");
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${Config.G_PERFORM_LINK}/v2/get/all/products?nameSearch=${nameSearch}&expireDateSearch=${validiteSearch}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    await axios
      .request(config)
      .then((response) => {
        setLoading(false);
        console.log(response.data.products);
        setProductList(response.data.products);
      })
      .catch((error) => {
        if (error && error.response.status === 404) {
          setLoading(false);
          setNotFound(true);
        }
        console.log(error);
      });
  };

  // Fetch All Products
  useEffect(() => {
    fetchAllProduct();
  }, [nameSearch, validiteSearch]);

  // Remove Carnet
  const removeCarnet = async () => {
    const token = sessionStorage.getItem("admin_tk");
    let config = {
      method: "delete",
      maxBodyLength: Infinity,
      url: `${Config.G_PERFORM_LINK}/v2/remove/carnet/${carnetID}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    await axios
      .request(config)
      .then((response) => {
        toast.success(response.data.message);
        setTimeout(() => {
          window.location.reload();
        }, 1500);
      })
      .catch((error) => {
        toast.error(error.response.data.message);
        console.log(error);
      });
  };

  // Remove Carnet
  const removeVal = async () => {
    const token = sessionStorage.getItem("admin_tk");
    let config = {
      method: "delete",
      maxBodyLength: Infinity,
      url: `${Config.G_PERFORM_LINK}/v2/remove/validite/${valID}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    await axios
      .request(config)
      .then((response) => {
        toast.success(response.data.message);
        setTimeout(() => {
          window.location.reload();
        }, 1500);
      })
      .catch((error) => {
        toast.error(error.response.data.message);
        console.log(error);
      });
  };

  // Remove Carnet
  const removeProduct = async () => {
    const token = sessionStorage.getItem("admin_tk");
    let config = {
      method: "delete",
      maxBodyLength: Infinity,
      url: `${Config.G_PERFORM_LINK}/v2/remove/product/${productID}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    await axios
      .request(config)
      .then((response) => {
        toast.success(response.data.message);
        setTimeout(() => {
          window.location.reload();
        }, 1500);
      })
      .catch((error) => {
        toast.error(error.response.data.message);
        console.log(error);
      });
  };

  // edite product info
  console.log("valDetails:", valDetails);
  console.log("valSelect:", valSelect);

  const handleSelectCarnet = (carnet) => {
    const isSelected = valSelect.includes(carnet.temp_expiration);

    if (isSelected) {
      // Retirer l'élément de valSelect et de valDetails
      setValSelect((prevSelect) =>
        prevSelect.filter((item) => item !== carnet.temp_expiration)
      );
      setValDetails((prevDetails) => {
        const newDetails = { ...prevDetails };
        delete newDetails[carnet.temp_expiration];
        return newDetails;
      });
      setValID((prevID) => prevID.filter((id) => id !== carnet.id));
    } else {
      // Ouvrir la modale pour saisir les valeurs de mise et de commission
      setModal9(true);

      // Stocker l'information du carnet actuellement sélectionné
      setCurrentCarnet(carnet);

      // Ajouter l'ID à valID
      setValID((prevID) => [...prevID, carnet.id]);
    }

    setValExpire(carnet.temp_expiration);
  };

  // Sauvegarder les détails de la mise et de la commission
  const handleSaveDetails = () => {
    console.log("Saving details for:", currentCarnet.temp_expiration);

    setValSelect((prevSelect) => [
      ...prevSelect,
      currentCarnet.temp_expiration,
    ]);

    setValDetails((prevDetails) => ({
      ...prevDetails,
      [currentCarnet.temp_expiration]: {
        mise: mise || "",
        commission: commission || "",
      },
    }));

    // Fermer la modale après avoir sauvegardé
    setModal9(false);
    setMise("");
    setCommission("");
  };

  return (
    <>
      <div
        onClickCapture={() => {
          setListKeyShow(false);
          setvalListe(false);
        }}
        className="py-[60px] w-full flex-col  max-sm:px-2"
      >
        {/* Header */}
        <div className="w-full mb-2 flex flex-row items-center justify-between px-3 max-sm:px-0">
          <div className="flex flex-row itemn-center">
            {/* Add NEW product*/}
            <button
              title="Ajouter"
              onClick={() => setModal1(true)}
              className=" bg-[#ADADAD36] hover:bg-slate-800 max-sm:w-[150px] max-sm:h-10 hover:text-slate-700 rounded-full flex-wrap items-center p-3 max-sm:p-1 flex flex-row"
            >
              <PiCardsThreeLight size={20} color="white" />
              <p className="text-white ml-2 text-xs">Crée un produit</p>
            </button>

            {/* Add new validite Model */}
            <button
              title="Ajouter"
              onClick={() => setModal3(true)}
              className=" bg-[#ADADAD36] hover:bg-slate-800 ml-3 max-sm:w-[150px] max-sm:h-10 hover:text-slate-700 rounded-full flex-wrap items-center p-3 max-sm:p-1 flex flex-row"
            >
              <IoIosTimer size={20} color="white" />
              <p className="text-white ml-2 text-xs">
                Crée un model de validié
              </p>
            </button>

            {/* Add new validite */}
            <button
              title="Ajouter"
              onClick={() => setModal2(true)}
              className=" bg-[#ADADAD36] hover:bg-slate-800 ml-3 max-sm:w-[150px] max-sm:h-10 hover:text-slate-700 rounded-full flex-wrap items-center p-3 max-sm:p-1 flex flex-row"
            >
              <MdOutlineShoppingCart size={20} color="white" />
              <p className="text-white ml-2 text-xs">Crée un carnet</p>
            </button>
          </div>

          {/* Choise Liste */}
          <div>
            <button
              onClick={() => setListKeyShow(!listKeyShow)}
              className="flex flex-row items-center hover:bg-slate-800 rounded-full p-2"
            >
              <p className="text-white"> Liste des </p>{" "}
              <p className="text-white ml-2">
                {listKey === 0
                  ? "Carnets"
                  : listKey === 1
                  ? "Produits"
                  : "Validités"}
              </p>
              <IoMdArrowDropdown className="text-white" />
            </button>
          </div>

          {/* Searchbar */}
          <div
            title="Recherche"
            className="bg-[#ADADAD36] w-[300px] max-sm:w-[auto] p-2 flex flex-row items-center"
          >
            <CiSearch size={25} color="white" />
            <input
              type="search"
              placeholder="Recheche de carnet"
              className="border-none outline-none bg-transparent w-full h-full text-white ml-2"
              value={nameSearch}
              onChange={(e) => setNameSearch(e.target.value)}
            />

            {/* Advance */}
            <button
              onClick={() => {
                setvalListe(!sValListe);
                fetchAllValiditeModel();
              }}
              className="bg-[#ffffff24] hover:bg-slate-800 p-2 rounded-md"
            >
              <CiFilter size={20} color="white" />
            </button>
          </div>
        </div>

        {/* Carnet List */}
        {loading ? (
          <div className="flex h-[80vh] flex-col items-center justify-center">
            <img src={Load} className="w-12 h-12" />
          </div>
        ) : notFound ? (
          <div className="flex flex-col items-center justify-center">
            <img src={Nothing} className="w-32 h-32" />
            <p className="text-white">Aucun carnet trouvé.</p>
          </div>
        ) : listKey === 0 ? (
          <div className="flex flex-wrap px-2">
            {productList.map((carnet, index) => (
              // Render each carnet here
              <div
                key={index}
                className="bg-[#ffffff24] flex flex-row rounded-xl hover:bg-slate-950 p-2 m-2 w-[24%] max-sm:w-full h-[250px]"
              >
                {/* Datas */}
                <div className="flex flex-row w-full justify-between">
                  {/* Left */}
                  <div className="ml-3 flex flex-col justify-between">
                    <p className="text-red-500"> Ref : {carnet.product_ref}</p>
                    <p className="text-green-500 font-bold text-base">
                      {carnet.nom_produit}
                    </p>
                    <p className="text-white">
                      {" "}
                      Commission :{" "}
                      {(
                        parseFloat(carnet.commission).toFixed(0) * 1
                      ).toLocaleString("fr-FR") +
                        " " +
                        "F"}
                    </p>
                    <p className="text-white">
                      {" "}
                      Prix achat :{" "}
                      {(
                        parseFloat(carnet.prix_achat).toFixed(0) * 1
                      ).toLocaleString("fr-FR") +
                        " " +
                        "F"}
                    </p>
                    <p className="text-white">
                      {" "}
                      Prix vente :{" "}
                      {(
                        parseFloat(carnet.prix_vente).toFixed(0) * 1
                      ).toLocaleString("fr-FR") +
                        " " +
                        "F"}
                    </p>
                    <p className="text-white">
                      {" "}
                      Mise :{" "}
                      {(parseFloat(carnet.mise).toFixed(0) * 1).toLocaleString(
                        "fr-FR"
                      ) +
                        " " +
                        "F"}
                    </p>
                    <p className="text-white">
                      {" "}
                      Marge :{" "}
                      {(parseFloat(carnet.marge).toFixed(0) * 1).toLocaleString(
                        "fr-FR"
                      ) +
                        " " +
                        "F"}
                    </p>
                    <p className="text-white">
                      Nbre de vente : {carnet.sell_counter} Fois
                    </p>
                    <p className="text-white">
                      {" "}
                      Date : {moment(carnet.created_at).calendar()}
                    </p>
                  </div>

                  {/* Right */}
                  <div className="w-10 rounded-full flex flex-col items-center justify-between">
                    <div className="w-10 h-5 rounded-full flex p-1 bg-white items-center justify-between">
                      <p className="text-red-500">
                        {" "}
                        V {carnet.validity_expire}
                      </p>
                    </div>

                    {/* Edite info */}
                    <button>
                      <FaPencilAlt
                        color="white"
                        className="hover:text-blue-700"
                      />
                    </button>

                    {/* Delete product */}
                    <button
                      onClick={() => {
                        setProductID(carnet.id);
                        setModal8(true);
                      }}
                    >
                      <IoTrashBinOutline
                        color="white"
                        className="hover:text-red-700"
                      />
                    </button>
                  </div>
                </div>
              </div>
            ))}
          </div>
        ) : listKey === 1 ? (
          <div className="flex flex-wrap ">
            {carnetList.map((carnet, index) => (
              // Render each carnet here
              <div
                key={index}
                className="bg-[#ffffff24] flex flex-row rounded-xl hover:bg-slate-950 p-2 m-2 w-2.5/12 max-sm:w-full"
              >
                {/* Datas */}
                <div className="flex flex-row w-full justify-between">
                  {/* Left */}
                  <div className="ml-3 flex flex-col justify-between">
                    <p className="text-red-500"> Ref : {carnet.ref}</p>

                    <p className="text-green-500 font-bold text-base">
                      {carnet.nom}
                    </p>

                    <p className="text-white">
                      {" "}
                      Prix achat :{" "}
                      {(
                        parseFloat(carnet.prix_achat).toFixed(0) * 1
                      ).toLocaleString("fr-FR") +
                        " " +
                        "F"}
                    </p>

                    <p className="text-white">
                      Date : {moment(carnet.create_at).calendar()}
                    </p>
                  </div>

                  {/* Right */}
                  <div className="w-10 rounded-full flex flex-col items-center justify-between">
                    {/* Delete product */}
                    <button
                      onClick={() => {
                        setModal6(true);
                        setCarnetID(carnet.id);
                      }}
                    >
                      <IoTrashBinOutline
                        color="white"
                        className="hover:text-red-700"
                      />
                    </button>
                  </div>
                </div>
              </div>
            ))}
          </div>
        ) : (
          listKey === 2 && (
            <div className="flex flex-wrap ">
              {validiteList.map((carnet, index) => (
                // Render each carnet here
                <div
                  key={index}
                  className="bg-[#ffffff24] flex flex-row rounded-xl hover:bg-slate-950 p-2 m-2 w-2.5/12 max-sm:w-full"
                >
                  {/* Datas */}
                  <div className="flex flex-row w-full justify-between">
                    {/* Left */}
                    <div className="ml-3 flex flex-col justify-between">
                      <p className="text-green-500 font-bold text-base">
                        {carnet.temp_expiration} {carnet.nom}
                      </p>

                      <p className="text-white">
                        Date : {moment(carnet.created_at).calendar()}
                      </p>
                    </div>

                    {/* Right */}
                    <div className="w-10 rounded-full flex flex-col items-center justify-between">
                      {/* Delete product */}
                      <button
                        onClick={() => {
                          setValID(carnet.id);
                          setModal7(true);
                        }}
                      >
                        <IoTrashBinOutline
                          color="white"
                          className="hover:text-red-700"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )
        )}
      </div>

      {/* Create New product */}
      <div
        className={
          modal1
            ? "w-[84%] h-[100vh] bg-[#38373769] absolute top-0 flex items-center justify-center"
            : "hidden"
        }
      >
        <div className="bg-slate-800 w-3/12 h-[30%] rounded-xl p-3">
          {/* Header */}
          <div className="flex justify-center items-cneter">
            {/* Title */}
            <p className="text-white font-bold text-xl">Creation de produit</p>

            <button
              onClick={() => setModal1(false)}
              className="bg-gray-100 p-2 hover:bg-slate-500 absolute right-1 top-1 rounded-full flex items-center justify-center"
            >
              <IoMdClose />
            </button>
          </div>

          {/* Body */}
          <div>
            {/* Name */}
            <div>
              {/* Label */}
              <p className="text-white text-base font-medium">Nom</p>

              {/* input */}
              <div className="bg-white p-3 w-full rounded-xl">
                <input
                  className="border-none outline-none w-full"
                  value={carnetName}
                  onChange={(e) => setCarnetName(e.target.value)}
                  placeholder="Nom"
                />
              </div>
            </div>

            {/* Buy Price */}
            <div className="mt-5">
              {/* Label */}
              <p className="text-white text-base font-medium">Prix d'achat</p>

              {/* input */}
              <div className="bg-white p-3 w-full rounded-xl">
                <input
                  className="border-none outline-none w-full"
                  value={carnetPrice}
                  onChange={(e) => {
                    const input = e.target.value;
                    const filteredInput = input.replace(/[^\d.]/g, "");
                    setCarnetPrice(filteredInput);
                  }}
                  placeholder="Prix d'achat en FCFA"
                />
              </div>
            </div>
          </div>

          {/* button */}
          <div className="flex w-full bottom-2 mt-8 items-center justify-center">
            <button
              onClick={() => createNewCarnet()}
              disabled={carnetName.length < 5 || carnetPrice.length === 0}
              className={
                carnetName.length < 5 || carnetPrice.length === 0
                  ? "p-3 hover:bg-slate-600 bg-blue-800 rounded-xl opacity-50"
                  : "p-3 hover:bg-slate-600 bg-blue-800 rounded-xl"
              }
            >
              <p className="text-white">Creé un produit</p>
            </button>
          </div>
        </div>
      </div>

      {/* Add New Validité  Model*/}
      <div
        className={
          modal3
            ? "w-[84%] h-[100vh] bg-[#38373769] absolute top-0 flex items-center justify-center"
            : "hidden"
        }
      >
        <div className="bg-slate-800 w-2/12 h-[20%] rounded-xl p-3">
          {/* Header */}
          <div className="flex justify-center items-cneter">
            {/* Title */}
            <p className="text-white font-bold text-xl">
              Creé un model de validité
            </p>

            <button
              onClick={() => setModal3(false)}
              className="bg-gray-100 hover:bg-slate-600 p-2 absolute right-1 top-1 rounded-full flex items-center justify-center"
            >
              <IoMdClose />
            </button>
          </div>

          {/* Body */}
          <div>
            <div>
              {/* Label */}
              <p className="text-white text-base font-medium">Validité</p>

              {/* input */}
              <div className="p-2 bg-white rounded-xl">
                <input
                  className="w-full border-none outline-none"
                  maxLength={3}
                  placeholder="Validité en jour"
                  value={valDay}
                  onChange={(e) => {
                    const input = e.target.value;
                    const filteredInput = input.replace(/[^\d.]/g, "");
                    setValDay(filteredInput);
                  }}
                />
              </div>
            </div>
          </div>

          {/* button */}
          <div className="flex w-full bottom-2 mt-8 items-center justify-center">
            <button
              onClick={() => createVal()}
              disabled={valDay.length === 0 || valDay == 0}
              className={
                valDay.length === 0 || valDay == 0
                  ? "p-3 hover:bg-slate-600 bg-blue-800 rounded-xl opacity-50"
                  : "p-3 hover:bg-slate-600 bg-blue-800 rounded-xl"
              }
            >
              <p className="text-white">Creé une validité</p>
            </button>
          </div>
        </div>
      </div>

      {/* Add New carnet */}
      <div
        className={
          modal2
            ? "w-[84%] h-[100vh] bg-[#38373769] absolute top-0 flex items-center justify-center"
            : "hidden"
        }
      >
        <div className="bg-slate-800 w-3/12 h-[35%] rounded-xl p-3">
          {/* Header */}
          <div className="flex justify-center items-cneter">
            {/* Title */}
            <p className="text-white font-bold text-xl">Crée un carnet</p>

            <button
              onClick={() => setModal2(false)}
              className="bg-gray-100 hover:bg-slate-600 p-2 absolute right-1 top-1 rounded-full flex items-center justify-center"
            >
              <IoMdClose />
            </button>
          </div>

          {/* Body */}
          <div className="h-[60%] mt-5 flex flex-col justify-between px-5">
            {/* Carnet select */}
            <div className="bg-slate-700 rounded-xl hover:bg-slate-800">
              <button
                onClick={() => setModal4(true)}
                className="p-3 w-full flex flex-row items-center justify-between"
              >
                <p className="text-white text-base font-bold">
                  {carnetSName ? carnetSName : "Selectionner un produit"}
                </p>
                <IoMdArrowDropdown size={15} color="white" />
              </button>
            </div>

            {/* Validite select */}
            <div className="bg-slate-700 rounded-xl hover:bg-slate-800">
              <button
                onClick={() => setModal5(true)}
                className="p-3 w-full flex flex-row items-center justify-between"
              >
                <p className="text-white text-base font-bold">
                  {valSelect
                    ? `${valSelect} Jours `
                    : "Selectionner une validité"}
                </p>

                <IoMdArrowDropdown size={15} color="white" />
              </button>
            </div>

            {/* Prix Achat */}
            <div className="bg-slate-700 p-3 rounded-xl flex flex-row items-center">
              <p className="font-bold text-white">Prix d'achat : </p>
              <p className="font-bold text-red-500 ml-2">
                {(parseFloat(prixAchat).toFixed(0) * 1).toLocaleString(
                  "fr-FR"
                ) +
                  " " +
                  "F"}
              </p>
            </div>
          </div>

          {/*  button */}
          <div className="flex w-full bottom-2 mt-8 items-center justify-center">
            <button
              onClick={() => createProduct()}
              disabled={carnetID.length === 0 || prixAchat.length === 0}
              className={
                carnetID.length === 0 || prixAchat.length === 0
                  ? "p-3 hover:bg-slate-600 bg-blue-800 rounded-xl opacity-50"
                  : "p-3 hover:bg-slate-600 bg-blue-800 rounded-xl"
              }
            >
              <p className="text-white">Creé un carnet</p>
            </button>
          </div>
        </div>
      </div>

      {/* Val list */}
      <div
        className={
          sValListe
            ? "w-[180px] h-[auto] absolute right-5 top-[120px] bg-[#ADADAD24] z-10 rounded-xl bg-"
            : "hidden"
        }
      >
        {/* Title */}
        <p className="text-center font-bold text-base text-white">Validités</p>
        <div className="flex w-full flex-wrap items-center justify-between p-2">
          {valModel.map((model, index) => (
            <button
              onClick={() => {
                setSelect(model.id);
                setValiditeSearch(model.temp_expiration);
              }}
              className={
                select === model.id
                  ? " bg-slate-500 m-1 w-2/5 flex flex-row hover:bg-gray-500 p-2 rounded-xl"
                  : "bg-gray-200 m-1 w-2/5 flex flex-row hover:bg-slate-500 p-2 rounded-xl"
              }
              key={index}
            >
              <p>{model.temp_expiration}</p>
              <p>{model.nom}</p>
            </button>
          ))}
        </div>
      </div>

      {/* Select list type pop up */}
      {listKeyShow ? (
        <div className="w-full flex justify-center">
          <div className="flex flex-col absolute ml-[180px] top-[120px] items-center bg-gray-800 h-[140px] p-2 rounded-xl">
            {listType.map((item) => (
              <button
                key={item.id}
                onClick={() => {
                  setListKeyShow(false);
                  setListeKey(item.id);
                }}
                className={
                  listKey === item.id
                    ? "p-2 flex flex-row items-center bg-gray-900 rounded-xl"
                    : "p-2 flex flex-row items-center hover:bg-gray-900 rounded-xl"
                }
              >
                {item.ico}
                <p
                  className={
                    listKey === item.id
                      ? "text-white hover:font-bold ml-2 font-bold"
                      : "text-white hover:font-bold ml-2"
                  }
                >
                  {item.title}
                </p>
              </button>
            ))}
          </div>
        </div>
      ) : (
        "hidden"
      )}

      {/* Select Carnets for create product */}
      <div className={modal4 ? "w-full h-full flex justify-center" : "hidden"}>
        <div className="absolute w-6/12 top-[30%] h-[40%] bg-cyan-950 rounded-xl p-2">
          <button
            onClick={() => setModal4(false)}
            className="bg-gray-100 hover:bg-slate-600 p-2 absolute right-1 top-1 rounded-full flex items-center justify-center"
          >
            <IoMdClose />
          </button>

          {/* Body carnet Liste */}
          <div>
            <div className="flex flex-wrap ">
              {carnetList.map((carnet, index) => (
                // Render each carnet here
                <button
                  onClick={() => {
                    setCarnetID(carnet.id);
                    setCarnetSName(carnet.nom);
                    setPrixAchat(carnet.prix_achat);
                    setModal4(false);
                  }}
                  key={index}
                  className={
                    carnetID === carnet.id
                      ? "flex flex-row rounded-xl bg-slate-950 p-2 m-2 w-2.5/12 max-sm:w-full"
                      : "bg-[#ffffff24] flex flex-row rounded-xl hover:bg-slate-950 p-2 m-2 w-2.5/12 max-sm:w-full"
                  }
                >
                  {/* Datas */}
                  <div className="flex flex-row w-full justify-between">
                    {/* Left */}
                    <div className="ml-3 flex flex-col justify-between">
                      <p className="text-red-500"> Ref : {carnet.ref}</p>

                      <p className="text-green-500 font-bold text-base">
                        {carnet.nom}
                      </p>

                      <p className="text-white">
                        {" "}
                        Prix achat :{" "}
                        {(
                          parseFloat(carnet.prix_achat).toFixed(0) * 1
                        ).toLocaleString("fr-FR") +
                          " " +
                          "F"}
                      </p>

                      <p className="text-white">
                        Date : {moment(carnet.create_at).calendar()}
                      </p>
                    </div>
                  </div>
                </button>
              ))}
            </div>
          </div>
        </div>
      </div>

      {/* Select Validite for create product */}
      <div className={modal5 ? "w-full h-full flex justify-center" : "hidden"}>
        <div className="absolute w-6/12 top-[30%] h-[40%] bg-cyan-950 flex flex-col justify-between rounded-xl p-2">
          {/* Close */}
          <button
            onClick={() => setModal5(false)}
            className="bg-gray-100 hover:bg-slate-600 p-2 absolute right-1 top-1 rounded-full flex items-center justify-center"
          >
            <IoMdClose />
          </button>

          {/* Body carnet Liste */}
          <div>
            {/* Datas */}
            <div className="flex flex-wrap ">
              {validiteList.map((carnet, index) => (
                <button
                  key={index}
                  onClick={() => handleSelectCarnet(carnet)}
                  className={`flex flex-row rounded-xl p-2 m-2 w-2.5/12 max-sm:w-full ${
                    valSelect.includes(carnet.temp_expiration)
                      ? "bg-slate-900 hover:bg-slate-950"
                      : "bg-[#ffffff24] hover:bg-slate-950"
                  }`}
                >
                  <div className="flex flex-row w-full justify-between">
                    <div className="ml-3 flex flex-col justify-between">
                      {valSelect.includes(carnet.temp_expiration) && (
                        <IoMdCheckmarkCircleOutline
                          className="absolute"
                          size={25}
                          color="red"
                        />
                      )}

                      <div>
                        <p className="text-green-500 font-bold text-base">
                          {carnet.temp_expiration} {carnet.nom}
                        </p>

                        <p className="text-white">
                          Date : {moment(carnet.created_at).calendar()}
                        </p>

                        {valSelect.includes(carnet.temp_expiration) && (
                          <>
                            {/* Commission */}
                            <div className="bg-[#6ae60524] px-2 py-1 rounded-full">
                              <p className="text-[#6ae605]">
                                Commission :{" "}
                                {valDetails[carnet.temp_expiration]?.commission}{" "}
                                F
                              </p>
                            </div>

                            {/* Mise */}
                            <div className="bg-[#eefa0a24] mt-1 py-1 rounded-full">
                              <p className="text-[#eefa0a]">
                                Mise :{" "}
                                {valDetails[carnet.temp_expiration]?.mise} F
                              </p>
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </button>
              ))}
            </div>
          </div>

          {/* Continuer Button */}
          <div className="w-full flex justify-center">
            <button
              disabled={valID.length === 0}
              onClick={() => setModal5(false)}
              className={
                valID.length === 0
                  ? "bg-blue-600 w-[30%] hover:bg-blue-800 text-white p-3 rounded-xl flex items-center justify-center mt-5 opacity-50"
                  : "bg-blue-600 w-[30%] hover:bg-blue-800 text-white p-3 rounded-xl flex items-center justify-center mt-5"
              }
            >
              Continuer
            </button>
          </div>
        </div>
      </div>

      {/* Carnet delete popup */}
      <div className={modal6 ? "w-full h-full flex justify-center" : "hidden"}>
        <div className="absolute w-3/12 top-[20%] h-[15%] bg-cyan-950 rounded-xl p-2">
          <button
            onClick={() => setModal6(false)}
            className="bg-gray-100 hover:bg-slate-600 p-2 absolute right-1 top-1 rounded-full flex items-center justify-center"
          >
            <IoMdClose />
          </button>

          {/* Body carnet Liste */}
          <div className="w-full h-full">
            <div>
              <p className="text-white text-lg font-semibold text-center">
                Voulez-vous supprimer ce carnet ?
              </p>

              {/* Button */}
              <div className="flex mt-10 flex-row justify-between">
                <button
                  onClick={() => setModal6(false)}
                  className="bg-blue-600 w-[48%] hover:bg-blue-800 text-white p-3 rounded-xl flex items-center justify-center"
                >
                  Non
                </button>

                <button
                  className="bg-red-600 w-[48%] p-3 hover:bg-red-800 text-white rounded-xl flex items-center justify-center"
                  onClick={() => removeCarnet()}
                >
                  Oui
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/*Val delete popup */}
      <div className={modal7 ? "w-full h-full flex justify-center" : "hidden"}>
        <div className="absolute w-3/12 top-[20%] h-[15%] bg-cyan-950 rounded-xl p-2">
          <button
            onClick={() => setModal7(false)}
            className="bg-gray-100 hover:bg-slate-600 p-2 absolute right-1 top-1 rounded-full flex items-center justify-center"
          >
            <IoMdClose />
          </button>

          {/* Body carnet Liste */}
          <div className="w-full h-full">
            <div>
              <p className="text-white text-lg font-semibold text-center">
                Voulez-vous supprimer cette validité ?
              </p>

              {/* Button */}
              <div className="flex mt-10 flex-row justify-between">
                <button
                  onClick={() => setModal7(false)}
                  className="bg-blue-600 w-[48%] hover:bg-blue-800 text-white p-3 rounded-xl flex items-center justify-center"
                >
                  Non
                </button>

                <button
                  className="bg-red-600 w-[48%] p-3 hover:bg-red-800 text-white rounded-xl flex items-center justify-center"
                  onClick={() => removeVal()}
                >
                  Oui
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/*Product delete popup */}
      <div className={modal8 ? "w-full h-full flex justify-center" : "hidden"}>
        <div className="absolute w-3/12 top-[20%] h-[15%] bg-cyan-950 rounded-xl p-2">
          <button
            onClick={() => setModal8(false)}
            className="bg-gray-100 hover:bg-slate-600 p-2 absolute right-1 top-1 rounded-full flex items-center justify-center"
          >
            <IoMdClose />
          </button>

          {/* Body carnet Liste */}
          <div className="w-full h-full">
            <div>
              <p className="text-white text-lg font-semibold text-center">
                Voulez-vous supprimer ce produit ?
              </p>

              {/* Button */}
              <div className="flex mt-10 flex-row justify-between">
                <button
                  onClick={() => setModal7(false)}
                  className="bg-blue-600 w-[48%] hover:bg-blue-800 text-white p-3 rounded-xl flex items-center justify-center"
                >
                  Non
                </button>

                <button
                  className="bg-red-600 w-[48%] p-3 hover:bg-red-800 text-white rounded-xl flex items-center justify-center"
                  onClick={() => removeProduct()}
                >
                  Oui
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Option of mise  */}
      <div
        className={
          modal9
            ? "w-[84%] h-[100vh] bg-[#38373769] absolute top-0 flex items-center justify-center"
            : "hidden"
        }
      >
        <div className="absolute w-3/12 top-[20%] h-[28%] bg-cyan-950 rounded-xl p-2">
          <button
            onClick={() => {
              setModal9(false);
            }}
            className="bg-gray-100 hover:bg-slate-600 p-2 absolute right-1 top-1 rounded-full flex items-center justify-center"
          >
            <IoMdClose />
          </button>

          {/* Finish Publishing Configuration */}
          <div className="w-full h-full">
            <div>
              <p className="text-white text-lg font-semibold text-center">
                Complèter les détails de ({currentCarnet?.temp_expiration}{" "}
                jours)
              </p>

              {/* body */}
              {/* Commission */}
              <div className="mt-3 px-10">
                {/* input */}
                <div className="bg-white p-3 w-full rounded-xl">
                  <input
                    className="border-none outline-none w-full"
                    value={commission}
                    onChange={(e) => {
                      const input = e.target.value;
                      const filteredInput = input.replace(/[^\d.]/g, "");
                      setCommission(filteredInput);
                    }}
                    placeholder="Commission"
                  />
                </div>

                {/* Prix de vente  */}
                <p className="text-white mt-3 font-medium text-base">
                  Prix de produit :{" "}
                  {(parseFloat(prixAchat).toFixed(0) * 1).toLocaleString(
                    "fr-FR"
                  ) +
                    " " +
                    "F"}
                </p>

                {/* Mise */}
                <div className="bg-white p-3 w-full mt-3 rounded-xl">
                  <input
                    className="border-none outline-none w-full"
                    value={mise}
                    onChange={(e) => {
                      const input = e.target.value;
                      const filteredInput = input.replace(/[^\d.]/g, "");
                      setMise(filteredInput);
                    }}
                    placeholder="Mise"
                  />
                </div>
              </div>

              {/* Button */}
              <div className="flex mt-10 flex-row justify-center">
                <button
                  onClick={handleSaveDetails}
                  className="bg-blue-600 w-[48%] hover:bg-blue-800 text-white p-3 rounded-xl flex items-center justify-center"
                >
                  Terminer
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <ToastContainer />
    </>
  );
}
