import React, { useEffect, useState } from "react";
import axios from "axios";
import { RiUser6Line } from "react-icons/ri";
import { LuUsers2 } from "react-icons/lu";
import { GrGroup } from "react-icons/gr";
import { PiPackage } from "react-icons/pi";
import { TbCardsFilled } from "react-icons/tb";
import { RiShoppingCartLine } from "react-icons/ri";
import { MdCreditCardOff } from "react-icons/md";
import { MdOutlineHistoryToggleOff } from "react-icons/md";
import { FaCircleUser } from "react-icons/fa6";
import Loading from "../../assets/rff.svg";
import Config from "../../config/Links";
import { Link } from "react-router-dom";
import moment from "moment";
import { CiFilter } from "react-icons/ci";
import "moment/locale/fr";
import AgencesListe from "./AgenceListe";

const url = Config.G_PERFORM_LINK;
const geSUrl = Config.GESCAPO_LINK;
const token = sessionStorage.getItem("admin_tk");

const state = [
  {
    id: 0,
    name: "Contrôleurs",
    icon: <RiUser6Line size={35} color="#22c55e" />,
  },
  { id: 1, name: "Collecteurs", icon: <LuUsers2 size={35} color="#ef4444" /> },
  { id: 2, name: "Clients", icon: <GrGroup size={35} color="#3b82f6" /> },
  { id: 3, name: "Produits", icon: <PiPackage size={35} color="#eab308" /> },
  {
    id: 4,
    name: "Carnet desister",
    icon: <MdCreditCardOff size={35} color="#a855f7" />,
  },
  { id: 5, name: "Carnets", icon: <TbCardsFilled size={35} color="#A0DEFF" /> },
  {
    id: 6,
    name: "Desistement",
    icon: <RiShoppingCartLine size={35} color="#F7DCB9" />,
  },
];

function NumberCol() {
  moment.locale("fr");
  const [controleurLength, setControleurLength] = useState("");
  const [collectorLength, setCollectorLength] = useState("");
  const [clientLength, setClientLength] = useState("");
  const [productsLength, setProductsLength] = useState([]);
  const [carnetDesteLength, setCarnetDesisteLength] = useState([]);
  const [carnetLength, setCarnetLength] = useState([]);
  const [desistementLength, setDesistementLength] = useState([]);

  // Admin Get Controleur length
  useEffect(() => {
    try {
      const ctrLength = async () => {
        const token = sessionStorage.getItem("admin_tk");
        const response = await axios.get(`${url}/v1/get/all/controleur`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        if (response.status === 200) {
          console.log(response);
          return setControleurLength(response.data?.controleur_length);
        }
      };
      ctrLength();
    } catch (error) {
      console.log(error);
    }
  }, []);

  // Admin Get Colllector length
  useEffect(() => {
    try {
      const ctrLength = async () => {
        const token = sessionStorage.getItem("gestoken");
        const response = await axios.post(
          `${geSUrl}/api/auth/admin/agent_collecteur/list?all=true`,
          {},
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
        if (response.status === 200) {
          setCollectorLength(response.data?.total);
        }
      };
      ctrLength();
    } catch (error) {
      console.log(error);
    }
  });

  // Admin Get Client length
  useEffect(() => {
    try {
      const ctrLength = async () => {
        const token = sessionStorage.getItem("gestoken");
        const response = await axios.post(
          `${geSUrl}/api/auth/admin/client/list`,
          {},
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
        if (response.status === 200) {
          setClientLength(response.data?.total);
        }
      };
      ctrLength();
    } catch (error) {
      console.log(error);
    }
  }, []);

  // Admin Get Products length
  useEffect(() => {
    try {
      const ctrLength = async () => {
        const token = sessionStorage.getItem("admin_tk");
        const response = await axios.get(`${url}/v1/get/store`, {
          headers: {},
        });
        if (response.status === 200) {
          return setProductsLength(response.data?.products?.length);
        }
      };
      ctrLength();
    } catch (error) {
      if (error.response.status === 40) {
        console.log("not found");
      }
      console.log(error);
    }
  }, []);

  // Admin Get Carnet Desister length
  useEffect(() => {
    try {
      const ctrLength = async () => {
        const token = sessionStorage.getItem("admin_tk");
        const response = await axios.get(
          `${url}/v1/admin/get/desistement/list`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
        if (response.status === 200) {
          return setCarnetDesisteLength(response.data?.data);
        }
      };
      ctrLength();
    } catch (error) {
      console.log(error);
    }
  }, []);

  // Admin Get Carnet length
  useEffect(() => {
    try {
      const ctrLength = async () => {
        const response = await axios.get(`${url}/get/all/carnet`, {
          headers: {},
        });
        if (response.status === 200) {
          return setCarnetLength(response.data?.liste?.length);
        }
      };
      ctrLength();
    } catch (error) {
      console.log(error);
    }
  }, []);

  // Admin Get Desistement length
  useEffect(() => {
    try {
      const ctrLength = async () => {
        const token = sessionStorage.getItem("admin_tk");
        const response = await axios.get(
          `${url}/v1/admin/get/desistement/list`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
        if (response.status === 200) {
          return setDesistementLength(response.data?.data?.length);
        }
      };
      ctrLength();
    } catch (error) {
      console.log(error);
    }
  }, []);

  return (
    <div className="h-[100vh] overflow-auto scrollbar-hide">
      <div className="mt-[65px] max-sm:pb-[5%] justify-start overflow-auto scrollbar-hide min-h-[300px] flex flex-wrap">
        {/* Statistique*/}
        {state.map(({ id, name, icon }) => (
          <div
            key={id}
            className="w-[230px] max-sm:w-full max-sm:h-[120px] flex flex-col p-3 m-2 max-sm:p-1 h-[130px] rounded-xl bg-[#ADADAD36]"
          >
            {/* Header */}
            <div className="flex flex-row items-center">
              <div
                className={
                  id === 0
                    ? "bg-[#22c55e24] p-2 rounded-full"
                    : id === 1
                    ? "bg-[#ef444424] p-2 rounded-full"
                    : id === 2
                    ? "bg-[#3b82f624] p-2 rounded-full"
                    : id === 3
                    ? "bg-[#eab30824] p-2 rounded-full"
                    : id === 4
                    ? "bg-[#a855f724] p-2 rounded-full"
                    : id === 5
                    ? "bg-[#A0DEFF24] p-2 rounded-full"
                    : id === 6 && "bg-[#F7DCB924] p-2 rounded-full"
                }
              >
                {icon}
              </div>
              <p className="text-white ml-3 font-bold text-lg">{name}</p>
            </div>

            {/* Number */}
            <p
              className={
                id === 0
                  ? "text-green-500 text-3xl font-bold m-3"
                  : id === 1
                  ? "text-red-500 text-3xl font-bold m-3"
                  : id === 2
                  ? "text-blue-500 text-3xl font-bold m-3"
                  : id === 3
                  ? "text-yellow-500 text-3xl font-bold m-3"
                  : id === 4
                  ? "text-purple-500 text-3xl font-bold m-3"
                  : id === 5
                  ? "text-[#A0DEFF] text-3xl font-bold m-3"
                  : id === 6 && "text-[#F7DCB9] text-3xl font-bold m-3"
              }
            >
              {id === 0
                ? <p>{controleurLength}</p> || "--"
                : id === 1
                ? <p>{collectorLength}</p> || "--"
                : id === 2
                ? <p>{clientLength}</p> || "--"
                : id === 3
                ? <p>{productsLength}</p> || "--"
                : id === 4
                ? (
                    <p>
                      {carnetDesteLength?.reduce(
                        (acc, current) => acc + current.total_carnet,
                        0
                      )}
                    </p>
                  ) || "--"
                : id === 5
                ? <p>{carnetLength}</p> || "--"
                : (id === 6 && <p>{desistementLength}</p>) || "--"}
            </p>
          </div>
        ))}
      </div>

      {/* Agence Listes */}
      <AgencesListe />
    </div>
  );
}

export default NumberCol;
