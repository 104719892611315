import axios from "axios";
import React, { useState, useEffect } from "react";
import Config from "../../config/Links";
import { Link } from "react-router-dom";
import { FaRegBuilding } from "react-icons/fa6";

const gesUrl = Config.GESCAPO_LINK;
const gesToken = sessionStorage.getItem("gestoken");

export default function AgenceListe() {
  const [agenceData, setAgenceData] = useState([]);

  // Fetch Agence
  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: `${gesUrl}/api/auth/admin/agence/list?all=true`,
    headers: {
      Authorization: `Bearer ${gesToken}`,
    },
  };

  useEffect(() => {
    const fetchAgence = async () => {
      await axios
        .request(config)
        .then((response) => {
          setAgenceData(response.data.data);
        })
        .catch((error) => {
          console.log(error);
        });
    };
    fetchAgence();
  }, []);

  return (
    <>
      <span className="flex flex-row my-2">
        <FaRegBuilding size={25} color="#fff" />
        <p className="text-white font-bold text-2xl ml-2">
          Liste des Agences ({agenceData.length})
        </p>
      </span>

      {/* Agence */}
      <div className="overflow-auto h-[60vh] scrollbar-hide rounded-md my-3 w-2/5">
        {[...agenceData]?.map((agence) => (
          <>
            <Link to={`/dashboard/colecteur/agence/info/${agence.id}`}>
              <div
                className="bg-gray-800 p-3 hover:bg-gray-500"
                key={agence.id}
              >
                <h3 className="text-white"> Nom : {agence.nom_agence}</h3>
                <p className="text-white">
                  Localisation : {agence.quartier.libelle}
                </p>
              </div>
            </Link>

            {/* Divider */}
            <div className="w-full h-[0.8px] bg-gray-200" />
          </>
        ))}
      </div>
    </>
  );
}
