import React from "react";
import NotFoundd from "../../assets/notFound.svg";

function NotFound() {
  return (
    <div className="flex h-screen justify-center items-center flex-col">
      <img src={NotFoundd} className="w-[250px] h-[250px]" />
      <p className="text-lg text-red-900 font-bold">Err Not Found !</p>
    </div>
  );
}

export default NotFound;
