import React, { useEffect, useMemo, useState, useCallback } from "react";
import axios from "axios";
import Loading from "../../assets/rff.svg";
import Nothing from "../../assets/rien.png";
import Report from "../../assets/chart.png";
import Config from "../../config/Links";
import { IoCloseSharp } from "react-icons/io5";
import { FaCreativeCommonsNc } from "react-icons/fa6";
import { CiSearch } from "react-icons/ci";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import qs from "qs";
import moment from "moment";
import "moment/locale/fr";

function RapportList() {
  moment.locale("fr");
  const [rapport, setRapport] = useState([]);
  const [loading, setLoading] = useState(true);
  const [search, setSearch] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [rapportID, setRapportID] = useState("");
  const [currentDette, setCurrentDette] = useState("");
  const [col_id, setColId] = useState("");
  const [colFilter, setColFilter] = useState({});

  const url = Config.G_PERFORM_LINK;
  const gesUrl = Config.GESCAPO_LINK;
  const token = sessionStorage.getItem("admin_tk");
  const gesToken = sessionStorage.getItem("gestoken");

  // Fetch Collector
  const fetchCol = useCallback(async () => {
    try {
      const response = await axios.post(
        `${gesUrl}/api/auth/admin/agent_collecteur/info/${col_id}`,
        null,
        {
          headers: { Authorization: `Bearer ${gesToken}` },
        }
      );
      setColFilter(response.data.data);
      console.log("colfilter", response.data.data);
    } catch (error) {
      console.log(error);
    }
  }, [col_id, gesToken, gesUrl]);

  useEffect(() => {
    if (col_id) {
      fetchCol();
    }
  }, [col_id, fetchCol]);

  // Fetch Reports
  const fetchTasks = useCallback(async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        `${url}/v1/admin/get/all/repport?query=${search}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      setRapport(response.data.data);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }, [search, token, url]);

  useEffect(() => {
    fetchTasks();
  }, [search, fetchTasks]);

  // Edit Dette
  const updateDette = async () => {
    const data = qs.stringify({
      edite_dette: currentDette,
    });

    try {
      const response = await axios.patch(
        `${url}/v1/admin/reduce/report/dept/${rapportID}`,
        data,
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      toast.success(response.data.message);
      setTimeout(() => {
        setShowModal(false);
        fetchTasks();
      }, 1000);
    } catch (error) {
      toast.error(error.response.data.message);
      console.log(error);
    }
  };

  useEffect(() => {
    setColId(rapport.map((ee) => ee.colecteur_id.toString()));
  }, [rapport]);

  return (
    <>
      <div className="py-[60px] w-full flex-col max-sm:px-2">
        {/* Search Bar */}
        <div className="w-full mb-2 flex flex-row items-center justify-between px-3 max-sm:px-0">
          {/* All Dette */}
          <div className="flex flex-row items-center">
            <p className="text-white text-xl">Dette globale :</p>
            <p className="text-green-500 ml-3 text-xl">
              {rapport
                .reduce((acc, cc) => acc + cc.dette, 0)
                .toLocaleString("fr-FR")}{" "}
              FCFA
            </p>
          </div>

          {/* Searchbar */}
          <div
            title="Recherche"
            className="bg-[#ADADAD36] w-[300px] max-sm:w-[auto] p-2 flex flex-row items-center"
          >
            <CiSearch size={25} color="white" />
            <input
              type="search"
              placeholder="Recherche de rapport"
              className="border-none outline-none bg-transparent w-full h-full text-white ml-2"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
          </div>
        </div>

        <div className="flex flex-row flex-wrap max-h-[85vh] pb-[10%] overflow-auto scrollbar-hide">
          {loading ? (
            <div className="w-full h-[70vh] mb-[50%] max-sm:mb-[50%] flex flex-grow justify-center items-center">
              <img src={Loading} className="w-20 h-20" />
            </div>
          ) : rapport?.length > 0 ? (
            rapport?.map(
              ({
                id,
                client_first_name,
                client_last_name,
                carnet_name,
                carnet_mise,
                probleme_source,
                number_of_case,
                dette,
                date,
              }) => {
                return (
                  <div
                    title="Tâches"
                    key={id}
                    className="bg-slate-900 w-[23.5%] max-sm:w-full my-2 min-h-[auto] rounded-md justify-between px-2 py-2 mx-2 flex flex-row hover:bg-[#282828] cursor-pointer"
                  >
                    <div className="p-1 w-20 h-20 justify-between items-center">
                      <img src={Report} className="w-10 h-10" />
                    </div>

                    <div className="flex flex-col w-full justify-start">
                      <p className="text-white font-bold text-lg">
                        {" "}
                        Client : {client_first_name}{" "}
                        {client_last_name.length > 10
                          ? `${client_last_name.slice(0, 10)}...`
                          : client_last_name}
                      </p>

                      {/* Collecteur Name */}
                      {/* <p className="text-white">
                        {" "}
                        Collecteur : {colFilter.nom} {colFilter.prenom}
                      </p> */}
                      <p className="text-white"> Carnet: {carnet_name}</p>
                      <p className="text-white"> Mise: {carnet_mise} FCFA</p>
                      <p className="text-white">
                        {" "}
                        Source:{" "}
                        {probleme_source == 0
                          ? "Carnet physique"
                          : probleme_source == 1 && "Carnet en ligne"}
                      </p>
                      <p className="text-white">
                        {" "}
                        Nbre de case: {number_of_case} Fois
                      </p>
                      <p className="text-white"> Dette: {dette} FCFA</p>
                      <p className="text-white">
                        Crée : {moment(date).calendar()}
                      </p>
                    </div>

                    {/* Tools */}
                    <div className="flex flex-col justify-start py-2">
                      {dette > 0 && (
                        <button
                          onClick={() => {
                            setShowModal(!showModal);
                            setRapportID(id);
                            setCurrentDette(dette);
                          }}
                          title="Diminuer les dettes"
                        >
                          <FaCreativeCommonsNc size={25} color="white" />
                        </button>
                      )}
                    </div>
                  </div>
                );
              }
            )
          ) : (
            <div className="flex w-full justify-center items-center h-70vh]">
              <div className="flex flex-col justify-center mt-[100px] items-center max-sm:mb-[50%]">
                <img src={Nothing} className="w-20 h-20 mb-4" />
                <p className="font-bold text-white mb-5">
                  Aucun rapport disponible !
                </p>
              </div>
            </div>
          )}
        </div>
      </div>

      {/* Reduce Depte Modal */}
      <div
        className={
          !showModal
            ? "hidden"
            : "flex w-full bg-transparent h-full justify-center items-center"
        }
      >
        <div
          className={
            !showModal
              ? "hidden"
              : "absolute w-[310px] h-[270px] flex flex-col max-sm:w-full rounded-xl justify-center bg-gray-900 top-[25%]"
          }
        >
          <div className="flex absolute top-4 items-start justify-center w-full">
            <div className="p-3 rounded-full bg-[#F1000029]">
              <FaCreativeCommonsNc size={30} color="red" />
            </div>

            {/* Close */}
            <button
              onClick={() => setShowModal(!showModal)}
              className="bg-[#ADADAD36] absolute right-1 rounded-full p-2"
            >
              <IoCloseSharp color="white" />
            </button>
          </div>

          {/* Title */}
          <div className="mb-2">
            <p className="text-white font-bold text-xl text-center">
              Mettre à jour la dette ?
            </p>
          </div>

          {/* Ccc */}
          <div className="px-10">
            <div className="bg-[#ADADAD36] p-3 flex flex-row items-center">
              <input
                defaultValue={currentDette}
                onChange={(e) => setCurrentDette(e.target.value)}
                className="bg-transparent text-white w-full h-full border-none outline-none"
                placeholder="Dette Actuelle ici"
              />
              <p className="text-white">FCFA</p>
            </div>
          </div>

          {/* Confirm Button */}
          <div className="px-[10%] w-full absolute flex justify-between bottom-5">
            {/* Confirme Dette */}
            <button
              onClick={() => updateDette()}
              className="w-full h-10 bg-red-600 rounded-md hover:bg-slate-600 mx-2"
            >
              <p className="text-white font-medium text-base">Mise à jour</p>
            </button>
          </div>
        </div>
      </div>

      {/* Toast */}
      <ToastContainer />
    </>
  );
}

export default RapportList;
