import React, { useEffect, useState } from "react";
import Load from "../../../assets/rff.svg";
import Nothing from "../../../assets/rien.png";
import Config from "../../config/Links";
import { CiSearch } from "react-icons/ci";
import { FaPencilAlt } from "react-icons/fa";
import { IoTrashBinOutline } from "react-icons/io5";
import { IoMdClose } from "react-icons/io";
import { CiNoWaitingSign } from "react-icons/ci";
import { CiUser } from "react-icons/ci";
import { IoRefresh } from "react-icons/io5";
import { IoLockOpenOutline } from "react-icons/io5";
import { ToastContainer, toast } from "react-toastify";
import { LuBuilding } from "react-icons/lu";
import { LuUsers2 } from "react-icons/lu";
import { RiUserLine } from "react-icons/ri";
import { GoArchive } from "react-icons/go";
import { TbUserCancel } from "react-icons/tb";
import { IoMdArrowDropdown } from "react-icons/io";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment";
import "moment/locale/fr";
import axios from "axios";
import qs from "qs";
import Users from "../../../assets/svg/users.svg";

// List Type
const listType = [
  {
    id: 0,
    title: "Actifs",
    ico: <RiUserLine className="text-white" size={25} />,
  },
  {
    id: 1,
    title: "Suspendus",
    ico: <TbUserCancel className="text-white" size={25} />,
  },
  {
    id: 2,
    title: "Archiver",
    ico: <GoArchive className="text-white" size={25} />,
  },
];

// Advance Search
export default function CarnetsListLgg() {
  const [vendeurList, setVendeurList] = useState([]);
  const [listKey, setListeKey] = useState(0);
  const [agenceListe, setAgenceListe] = useState([]);
  const [listKeyShow, setListKeyShow] = useState(false);
  const [notFound, setNotFound] = useState(false);
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(true);
  const [modal, setModal] = useState(false);
  const [modal1, setModal1] = useState(false);
  const [modal2, setModal2] = useState(false);
  const [modal3, setModal3] = useState(false);
  const [modal4, setModal4] = useState(false);

  // Generate
  const avl = "gvendor@";
  const genPasse = (length) => {
    let result = "";
    const characters = "0123456789";
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
      counter += 1;
    }
    return avl + result;
  };

  // Vendor Ctreate forms States
  const [nom_utilisateur, setNom_utilisateur] = useState("");
  const [telephone, setTelephone] = useState("");
  const [passe, setPasse] = useState(genPasse(3));
  const [agenceID, setAgenceID] = useState("");
  const [agenceName, setAgenceName] = useState("");

  // Fetch Vendor List
  useEffect(() => {
    const fetchAllColl = async () => {
      const token = sessionStorage.getItem("admin_tk");
      let config = {
        method: "get",
        maxBodyLength: Infinity,
        url: `${Config.G_PERFORM_LINK}/v2/get/lgg/col/list?query=${search}`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      await axios
        .request(config)
        .then((response) => {
          setVendeurList(response.data.collecteurs);
          setNotFound(false);
          setLoading(false);
        })
        .catch((error) => {
          if (error.response.status === 400) {
            setNotFound(true);
          }
          setLoading(false);
          console.log(error);
        });
    };
    fetchAllColl();
  }, [search]);

  // Get All Agence Liste
  useEffect(() => {
    const token = sessionStorage.getItem("admin_tk");
    const fetchALLAgenceListe = async () => {
      let config = {
        method: "get",
        maxBodyLength: Infinity,
        url: `${Config.G_PERFORM_LINK}/v2/get/all/agences`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      await axios
        .request(config)
        .then((response) => {
          setAgenceListe(response.data.agences);
        })
        .catch((error) => {
          console.log(error);
        });
    };

    fetchALLAgenceListe();
  }, []);

  // Create Vendor
  const createVendor = async () => {
    const token = sessionStorage.getItem("admin_tk");
    let data = qs.stringify({
      nom_utilisateur: nom_utilisateur,
      telephone: telephone,
      mot_de_passe: passe,
      id_agence: agenceID,
      nom_agence: agenceName,
    });

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${Config.G_PERFORM_LINK}/v2/create/lgg/col`,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: `Bearer ${token}`,
      },
      data: data,
    };

    await axios
      .request(config)
      .then((response) => {
        if (response.status === 201) {
          toast.success(response.data.message);
          setModal(false);
          setTimeout(() => {
            window.location.reload();
          }, 1500);
        }
      })
      .catch((error) => {
        toast.error(error.response.data.message);
        console.log(error);
      });
  };

  // Edite Vendor

  // Suspense Vendor

  // Archive Vendor

  return (
    <>
      <div
        // onClickCapture={() => {

        // }}
        className="py-[60px] w-full flex-col  max-sm:px-2"
      >
        {/* Header */}
        <div className="w-full mb-2 flex flex-row items-center justify-between px-3 max-sm:px-0">
          <div className="flex flex-row itemn-center">
            {/* Add NEW carnet*/}
            <button
              title="Ajouter"
              onClick={() => setModal(true)}
              className=" bg-[#ADADAD36] hover:bg-slate-800 max-sm:w-[150px] max-sm:h-10 hover:text-slate-700 rounded-full flex-wrap items-center p-3 max-sm:p-1 flex flex-row"
            >
              <LuUsers2 size={20} color="white" />
              <p className="text-white ml-2 text-xs">Crée un vendeur</p>
            </button>
          </div>

          {/* Choise Liste Vendor */}
          <div>
            <button
              onClick={() => setListKeyShow(!listKeyShow)}
              className="flex flex-row items-center hover:bg-slate-800 rounded-full p-2"
            >
              <p className="text-white"> Vendeurs </p>{" "}
              <p className="text-white ml-2">
                {listKey === 0
                  ? "Actifs"
                  : listKey === 1
                  ? "Suspendus"
                  : "Archivés"}
              </p>
              <IoMdArrowDropdown className="text-white" />
            </button>
          </div>

          {/* Searchbar */}
          <div
            title="Recherche"
            className="bg-[#ADADAD36] w-[300px] max-sm:w-[auto] p-2 flex flex-row items-center"
          >
            <CiSearch size={25} color="white" />
            <input
              type="search"
              placeholder="Rechecher un vendeur"
              className="border-none outline-none bg-transparent w-full h-full text-white ml-2"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
          </div>
        </div>

        {/* Create vendor */}
        <div
          className={
            modal
              ? "w-[84%] h-[100vh] bg-[#38373769] absolute top-0 flex items-center justify-center"
              : "hidden"
          }
        >
          <div className="bg-slate-800 w-3/12 h-[45%] rounded-xl p-3">
            {/* Header */}
            <div className="flex justify-center items-cneter">
              {/* Title */}
              <p className="text-white font-bold text-xl">Crée un vendeur</p>

              <button
                onClick={() => setModal(false)}
                className="bg-gray-100 hover:bg-slate-600 p-2 absolute right-1 top-1 rounded-full flex items-center justify-center"
              >
                <IoMdClose />
              </button>
            </div>

            {/* Body */}
            <div className="h-[70%] mt-5 flex flex-col justify-between px-5">
              {/* Vendor name */}
              <div>
                {/* Label */}
                <div className="bg-white p-3 w-full rounded-xl">
                  <input
                    className="border-none outline-none w-full"
                    value={nom_utilisateur}
                    onChange={(e) => setNom_utilisateur(e.target.value)}
                    placeholder="Nom & Prénom"
                  />
                </div>
              </div>

              {/* telephone */}
              <div>
                {/* Label */}
                <div className="bg-white p-3 w-full rounded-xl">
                  <input
                    className="border-none outline-none w-full"
                    value={telephone}
                    maxLength={8}
                    onChange={(e) => {
                      const input = e.target.value;
                      const filteredInput = input.replace(/[^\d.]/g, "");
                      setTelephone(filteredInput);
                    }}
                    placeholder="Téléphone"
                  />
                </div>
              </div>

              {/* PassWord */}
              <div>
                {/* Label */}
                <div className="bg-slate-900 hover:bg-slate-700 flex flex-row items-center justify-between p-3 w-full rounded-xl">
                  <p className="font-medium text-base text-white">{passe}</p>

                  {/* Generate Passe */}
                  <button
                    onClick={() => setPasse(genPasse(3))}
                    className="hover:bg-slate-400 w-10 h-10 p-2 rounded-full flex items-center justify-center"
                  >
                    <IoRefresh color="#fff" />
                  </button>
                </div>
              </div>

              {/* Agence Name */}
              <div>
                {/* Label */}
                <button
                  onClick={() => setModal4(true)}
                  className="bg-slate-900 hover:bg-slate-700 flex flex-row items-center justify-between p-3 w-full rounded-xl"
                >
                  <p className="font-medium text-base text-white">
                    {agenceID !== "" && agenceName !== ""
                      ? agenceName
                      : "Sélectionner une agence"}
                  </p>
                  {/* ico */}
                  <IoMdArrowDropdown className="text-white" size={20} />
                </button>
              </div>
            </div>

            {/*  button */}
            <div className="flex w-full bottom-2 mt-8 items-center justify-center">
              <button
                disabled={
                  nom_utilisateur.length < 5 ||
                  telephone.length < 8 ||
                  agenceName.length === 0
                }
                onClick={() => createVendor()}
                className={
                  nom_utilisateur.length < 5 ||
                  telephone.length < 8 ||
                  agenceName.length === 0
                    ? "p-3 hover:bg-slate-600 bg-blue-800 rounded-xl opacity-50"
                    : "p-3 hover:bg-slate-600 bg-blue-800 rounded-xl"
                }
              >
                <p className="text-white">Creé un vendeur</p>
              </button>
            </div>
          </div>
        </div>

        {/* Vendor List */}
        {loading ? (
          <div className="flex h-[80vh] flex-col items-center justify-center">
            <img src={Load} className="w-12 h-12" />
          </div>
        ) : notFound ? (
          <div className="flex flex-col items-center justify-center">
            <img src={Nothing} className="w-32 h-32" />
            <p className="text-white">Aucun vendeur trouvé !</p>
          </div>
        ) : (
          listKey === 0 && (
            <div className="flex flex-wrap px-2">
              {vendeurList.map((vendor, index) => (
                // Render each carnet here
                <div
                  key={index}
                  className="bg-[#ffffff24] flex flex-row rounded-xl hover:bg-slate-950 py-2 m-2 w-[24%] max-sm:w-full "
                >
                  {/* Datas */}
                  <div className="flex flex-row w-full justify-between">
                    {/* Left */}
                    <div className="w-full flex flex-col justify-start">
                      <div className="w-full flex justify-center">
                        <img src={Users} className="w-12 h-12" />
                      </div>

                      {/* Body */}
                      <div className="flex flex-wrap">
                        {/* Name */}
                        <div className="bg-[#ffffff34] m-1 p-2 rounded-full flex flex-row items-center">
                          <CiUser className="text-white" />
                          <p className="text-white ml-2">
                            {vendor.nom_utilisateur.length > 20
                              ? vendor.nom_utilisateur.slice(0, 20) + "..."
                              : vendor.nom_utilisateur}
                          </p>
                        </div>

                        {/* phone */}
                        <p className="bg-[#ADADAD36] m-1 p-2 rounded-full text-white">
                          (228) {vendor.telephone}
                        </p>

                        {/* connected status */}
                        <p
                          className={
                            vendor.is_connect === 0
                              ? "bg-[#f5074334] m-1 p-2 rounded-full text-red-500"
                              : "bg-[#07f53334] m-1 p-2 rounded-full text-green-500"
                          }
                        >
                          {vendor.is_connect === 0 ? "Déconnecté" : "Connecté"}
                        </p>

                        {/* Secret */}
                        <div className="bg-[#bf00ff34] m-1 p-2 rounded-full flex flex-row items-center">
                          <IoLockOpenOutline className="text-[#bf00ff]" />
                          <p className="text-[#bf00ff] ml-2">
                            {vendor.mot_de_passe_visible}
                          </p>
                        </div>

                        {/* Secret */}
                        <div className="bg-[#002aff34] m-1 p-2 rounded-full flex flex-row items-center">
                          <IoLockOpenOutline className="text-[#002aff]" />
                          <p className="text-[#002aff] ml-2">
                            {vendor.code_secret}
                          </p>
                        </div>

                        {/* Agence */}
                        <div className="bg-[#ffee0034] m-1 p-2 rounded-full flex flex-row items-center">
                          <LuBuilding className="text-[#ffee00]" />
                          <p className="text-[#ffee00] ml-2">
                            {vendor.nom_agence}
                          </p>
                        </div>

                        {/* Commission */}
                        <div className="bg-[#00fffb34] m-1 p-2 rounded-full flex flex-row items-center">
                          <p className="text-[#00fffb] text-center">
                            Commission :{" "}
                          </p>
                          <p className="text-[#00fffb] ml-2">
                            {(
                              parseFloat(vendor.total_commission).toFixed(0) * 1
                            ).toLocaleString("fr-FR") +
                              " " +
                              " F"}
                          </p>
                        </div>

                        {/* Portfeuil Carnet */}
                        <div className="bg-[#E85C0D34] m-1 p-2 rounded-full flex flex-row items-center">
                          <p className="text-[#E85C0D] text-center">
                            Carnet vendus :{" "}
                          </p>
                          <p className="text-[#E85C0D] ml-2">
                            {(
                              parseFloat(vendor.portefeuille_carnet).toFixed(
                                0
                              ) * 1
                            ).toLocaleString("fr-FR") +
                              " " +
                              " F"}
                          </p>
                        </div>

                        {/* Nombre de carnets vendus */}
                        <div className="bg-[#00fffb34] m-1 p-2 rounded-full flex flex-row items-center">
                          <p className="text-[#00fffb] text-center">
                            Vente carnet :{" "}
                          </p>
                          <p className="text-[#00fffb] ml-2">
                            {vendor.nbre_carnet_vendue}
                          </p>
                        </div>

                        {/* Portefeuille Collecte */}
                        <div className="bg-[#EECEB934] m-1 p-2 rounded-full flex flex-row items-center">
                          <p className="text-[#EECEB9] text-center">
                            Collectes en cours :{" "}
                          </p>
                          <p className="text-[#EECEB9] ml-2">
                            {(
                              parseFloat(vendor.portefeuille_collect).toFixed(
                                0
                              ) * 1
                            ).toLocaleString("fr-FR") +
                              " " +
                              " F"}
                          </p>
                        </div>

                        {/* Nombre de client */}
                        <div className="bg-[#00fffb34] m-1 p-2 rounded-full flex flex-row items-center">
                          <p className="text-[#00fffb] text-center">
                            Nbre Clients :{" "}
                          </p>
                          <p className="text-[#00fffb] ml-2">
                            {vendor.nbre_client_cree}
                          </p>
                        </div>

                        {/* Deversement */}
                        <div className="bg-[#00fffb34] m-1 p-2 rounded-full flex flex-row items-center">
                          <p className="text-[#00fffb] text-center">
                            Déversement :{" "}
                          </p>
                          <p className="text-[#00fffb] ml-2">
                            {(
                              parseFloat(vendor.total_deversement).toFixed(0) *
                              1
                            ).toLocaleString("fr-FR") +
                              " " +
                              " F"}
                          </p>
                        </div>
                      </div>
                    </div>

                    {/* Right */}
                    <div className="w-10 rounded-full flex flex-col items-center justify-between">
                      {/* Edite info */}
                      <button onClick={() => setModal1(true)}>
                        <FaPencilAlt
                          color="white"
                          className="hover:text-blue-700"
                        />
                      </button>

                      {/* Suspense Vendor */}
                      <button
                        onClick={() => {
                          setModal2(true);
                        }}
                      >
                        <CiNoWaitingSign
                          color="white"
                          className="hover:text-red-700 "
                        />
                      </button>

                      {/* Archive vendor */}
                      <button
                        onClick={() => {
                          setModal3(true);
                        }}
                      >
                        <IoTrashBinOutline
                          color="white"
                          className="hover:text-red-700"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )
        )}
      </div>

      {/* Select list type pop up */}
      {listKeyShow ? (
        <div className="w-full flex justify-center">
          <div className="flex flex-col absolute mr-[20px] top-[120px] items-center bg-gray-800 h-[140px] p-2 rounded-xl">
            {listType.map((item) => (
              <button
                key={item.id}
                onClick={() => {
                  setListKeyShow(false);
                  setListeKey(item.id);
                }}
                className={
                  listKey === item.id
                    ? "p-2 flex flex-row items-center bg-gray-900 rounded-xl"
                    : "p-2 flex flex-row items-center hover:bg-gray-900 rounded-xl"
                }
              >
                {item.ico}
                <p
                  className={
                    listKey === item.id
                      ? "text-white hover:font-bold ml-2 font-bold"
                      : "text-white hover:font-bold ml-2"
                  }
                >
                  {item.title}
                </p>
              </button>
            ))}
          </div>
        </div>
      ) : (
        "hidden"
      )}

      {/* Edite vendor Popup */}
      <div
        className={
          modal1
            ? "w-[84%] h-[100vh] bg-[#38373769] absolute top-0 flex items-center justify-center"
            : "hidden"
        }
      >
        <div className="absolute w-3/12 top-[20%] h-[15%] bg-cyan-950 rounded-xl p-2">
          <button
            onClick={() => setModal1(false)}
            className="bg-gray-100 hover:bg-slate-600 p-2 absolute right-1 top-1 rounded-full flex items-center justify-center"
          >
            <IoMdClose />
          </button>

          {/* Body carnet Liste */}
          <div className="w-full h-full">
            <div>
              {/* Header */}
              <p className="text-white text-lg font-semibold text-center">
                Modifier les infos de ce vendeur ?
              </p>

              {/* Button */}
              <div className="flex mt-10 justify-center">
                <button
                  // onClick={() => setModal6(false)}
                  className="bg-blue-600 w-[48%] hover:bg-blue-800 text-white p-3 rounded-xl flex items-center justify-center"
                >
                  Mettre à jour
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Suspense vendor Popup */}
      <div
        className={
          modal2
            ? "w-[84%] h-[100vh] bg-[#38373769] absolute top-0 flex items-center justify-center"
            : "hidden"
        }
      >
        <div className="absolute w-3/12 top-[20%] h-[15%] bg-cyan-950 rounded-xl p-2">
          <button
            onClick={() => setModal2(false)}
            className="bg-gray-100 hover:bg-slate-600 p-2 absolute right-1 top-1 rounded-full flex items-center justify-center"
          >
            <IoMdClose />
          </button>

          {/* Body carnet Liste */}
          <div className="w-full h-full">
            <div>
              {/* Header */}
              <p className="text-white text-lg font-semibold text-center">
                Suspendre ce vendeur ?
              </p>

              {/* Button */}
              <div className="flex mt-10 justify-center">
                <button
                  // onClick={() => setModal6(false)}
                  className="bg-blue-600 w-[48%] hover:bg-blue-800 text-white p-3 rounded-xl flex items-center justify-center"
                >
                  Cofirmer
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Archive vendor Popup */}
      <div
        className={
          modal3
            ? "w-[84%] h-[100vh] bg-[#38373769] absolute top-0 flex items-center justify-center"
            : "hidden"
        }
      >
        <div className="absolute w-3/12 top-[20%] h-[15%] bg-cyan-950 rounded-xl p-2">
          <button
            onClick={() => setModal3(false)}
            className="bg-gray-100 hover:bg-slate-600 p-2 absolute right-1 top-1 rounded-full flex items-center justify-center"
          >
            <IoMdClose />
          </button>

          {/* Body carnet Liste */}
          <div className="w-full h-full">
            <div>
              {/* Header */}
              <p className="text-white text-lg font-semibold text-center">
                Archiver ce vendeur ?
              </p>

              {/* Button */}
              <div className="flex mt-10 justify-center">
                <button
                  // onClick={() => setModal6(false)}
                  className="bg-blue-600 w-[48%] hover:bg-blue-800 text-white p-3 rounded-xl flex items-center justify-center"
                >
                  Cofirmer
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Select Agence for create vendor */}
      <div
        className={
          modal4
            ? "w-[84%] h-[100vh] bg-[#38373769] absolute top-0 flex items-center justify-center"
            : "hidden"
        }
      >
        <div className="absolute w-6/12 top-[30%] h-[40%] bg-cyan-950 rounded-xl p-2">
          <button
            onClick={() => {
              setModal4(false);
            }}
            className="bg-gray-100 hover:bg-slate-600 p-2 absolute right-1 top-1 rounded-full flex items-center justify-center"
          >
            <IoMdClose />
          </button>

          {/* Body Agence List */}
          <div>
            <div className="flex flex-wrap ">
              {agenceListe.map((agence, index) => (
                // Render each carnet here
                <button
                  onClick={() => {
                    setAgenceID(agence.id);
                    setAgenceName(agence.nom);
                    setModal3(false);
                    setModal4(false);
                  }}
                  key={index}
                  className={
                    agence.id === agenceID
                      ? "bg-slate-950 flex flex-row rounded-xl hover:bg-slate-950 p-2 m-2 w-2.5/12 max-sm:w-full"
                      : "bg-[#ffffff24] flex flex-row rounded-xl hover:bg-slate-950 p-2 m-2 w-2.5/12 max-sm:w-full"
                  }
                >
                  {/* Datas */}
                  <div className="flex flex-col items-center w-full justify-between">
                    {/* Left */}
                    <LuBuilding size={20} color="white" />

                    <p className="text-red-500 font-bold text-base">
                      {agence.nom}
                    </p>

                    <div className="ml-3 flex flex-col justify-between">
                      <p className="text-white font-bold text-base">
                        {agence.ville}
                      </p>

                      <p className="text-white font-bold text-base">
                        {agence.quartier}
                      </p>
                    </div>
                  </div>
                </button>
              ))}
            </div>
          </div>
        </div>
      </div>

      <ToastContainer />
    </>
  );
}
