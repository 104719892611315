import React, { useState } from "react";
import axios from "axios";
import Config from "../../config/Links";
import Loading from "../../assets/loading.svg";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { LiaCartPlusSolid } from "react-icons/lia";
import { RiMoneyEuroBoxFill } from "react-icons/ri";
import { MdOutlineProductionQuantityLimits } from "react-icons/md";
import { CiImageOn } from "react-icons/ci";
import { useNavigate } from "react-router-dom";

function CreateCarnet() {
  const navigation = useNavigate();
  const url = Config.G_PERFORM_LINK;
  const token = sessionStorage.getItem("admin_tk");

  const [imageFile, setImageFile] = useState(null);
  const [imagePreview, setImagePreview] = useState("");
  const [productName, setProductName] = useState("");
  const [buy_price, setBuyPrice] = useState(0);
  const [sell_price, setSellPrice] = useState(0);
  const [loading1, setLoading1] = useState(false);

  const form = new FormData();
  form.append("store_id", "1");
  form.append("product_name", productName);
  form.append("image_url", imageFile);
  form.append("buy_price", buy_price);
  form.append("sell_price", sell_price);

  const options = {
    method: "POST",
    url: `${url}/admin/create/desistement/product`,
    headers: {
      Authorization: `Bearer ${token}`,
      "content-type": "multipart/form-data",
    },
    data: form,
  };

  // Fonction pour envoyer la requête
  const createProduct = async () => {
    try {
      const response = await axios.request(options);
      if (response.status === 201) {
        toast.success(response.data.message);
        setTimeout(() => {
          navigation(-1);
        }, 1500);
      }
    } catch (error) {
      if (error.response && error.response.status === 400) {
        toast.error(error.response.data.message);
      } else if (error.response && error.response.status === 500) {
        toast.error("La taille du fichier est trop !");
        console.error(error);
      } else {
        toast.error("Erreur de création de produit !");
        console.log(error);
      }
    } finally {
      setLoading1(false);
    }
  };

  return (
    <>
      <div className="flex justify-center items-center mt-[70px] px-[32%] max-sm:px-0">
        <div className="flex w-full h-[90vh] bg-[#ADADAD36] rounded-xl max-sm:bg-transparent flex-col">
          {/* Header */}
          <div className="flex flex-row items-center w-full justify-center">
            {/* Add New Button */}
            <div className="flex flex-row h-20 items-center px-3">
              <LiaCartPlusSolid size={30} color="#fff" />
              <p className="text-white text-xl font-bold ml-3">
                Ajouter un nouveau produit
              </p>
            </div>
          </div>

          {/* Forms */}
          <form method="post" encType="multipart/form-data">
            <div className="px-[20%] max-sm:px-[10%]">
              {/* Img fetch uri */}
              <div className="mt-5">
                {imagePreview === "" ? (
                  <label
                    htmlFor="img"
                    className="cursor-pointer border-[1px] h-[200px] flex flex-col items-center justify-center border-white m-2 rounded-xl border-dashed"
                  >
                    <div className="flex flex-row items-center">
                      <input
                        type="file"
                        accept="image/*"
                        id="img"
                        name="image_url"
                        className="hidden w-0 h-0"
                        onChange={(e) => {
                          const file = e.target.files[0];
                          setImageFile(file);
                          setImagePreview(URL.createObjectURL(file));
                        }}
                      />

                      <LiaCartPlusSolid size={60} color="#fff" />
                      <p className="text-white mx-2">+</p>
                      <CiImageOn size={60} color="#fff" />
                    </div>
                    <p className="text-white my-6">
                      Ajouter l'image du produit
                    </p>
                  </label>
                ) : (
                  <img
                    src={imagePreview}
                    className="w-full h-full"
                    alt="Aperçu de l'image"
                  />
                )}
              </div>

              {/* Product Name */}
              <div className="bg-[#000000] max-sm:bg-slate-500 flex flex-row items-center mt-12 p-3 rounded-lg mb-4">
                <MdOutlineProductionQuantityLimits size={30} color="#fff" />
                <input
                  required
                  type="text"
                  placeholder="Nom du produit"
                  className="bg-transparent ml-3 h-full w-full text-white border-none outline-none"
                  value={productName}
                  onChange={(e) => setProductName(e.target.value)}
                />
              </div>

              {/*  Price */}
              <div className="bg-[#000000] max-sm:bg-slate-500 flex flex-row items-center p-3 rounded-lg mb-4">
                <RiMoneyEuroBoxFill size={30} color="#fff" />
                <input
                  required
                  type="text"
                  onFocus={() => setBuyPrice("")}
                  placeholder="Prix d'achat"
                  className="bg-transparent ml-3 h-full w-full text-white border-none outline-none"
                  value={buy_price}
                  onChange={(e) => {
                    const input = e.target.value;
                    const filteredInput = input.replace(/[^\d.]/g, "");
                    setBuyPrice(filteredInput);
                  }}
                />

                <p className="text-white">FCFA</p>
              </div>

              {/*  Price */}
              <div className="bg-[#000000] max-sm:bg-slate-500 flex flex-row items-center p-3 rounded-lg mb-4">
                <RiMoneyEuroBoxFill size={30} color="#fff" />
                <input
                  required
                  type="text"
                  onFocus={() => setSellPrice("")}
                  placeholder="Prix de vente"
                  className="bg-transparent ml-3 h-full w-full text-white border-none outline-none"
                  value={sell_price}
                  onChange={(e) => {
                    const input = e.target.value;
                    const filteredInput = input.replace(/[^\d.]/g, "");
                    setSellPrice(filteredInput);
                  }}
                />

                <p className="text-white">FCFA</p>
              </div>

              {/* Creat Controleur button */}
              <div className="flex justify-center items-center mt-10">
                {loading1 ? (
                  <img src={Loading} className="w-10 h-10" />
                ) : (
                  <button
                    onClick={() => {
                      createProduct();
                      setLoading1(!loading1);
                    }}
                    disabled={
                      imageFile === "" ||
                      productName === "" ||
                      buy_price === "" ||
                      sell_price === ""
                    }
                    className={
                      imageFile === "" ||
                      productName === "" ||
                      buy_price === "" ||
                      sell_price === ""
                        ? "bg-blue-900 p-3 w-[300px] rounded-xl opacity-60"
                        : "bg-blue-900 p-3 w-[300px] rounded-xl hover:bg-slate-900"
                    }
                  >
                    <p className="text-white">Crée un produit</p>
                  </button>
                )}
              </div>
            </div>
          </form>
        </div>

        {/* Toastify */}
        <ToastContainer />
      </div>
    </>
  );
}

export default CreateCarnet;
