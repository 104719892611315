import React, { useEffect, useState } from "react";
import { Link, Outlet } from "react-router-dom";
import Header from "../components/Header";
import Config from "../config/Links";

// Menu Ico
import { TbLayoutDashboard } from "react-icons/tb";
import { RiUserSmileLine } from "react-icons/ri";
import { LuUsers2 } from "react-icons/lu";
import { MdOutlineRemoveShoppingCart } from "react-icons/md";
import { PiCardsThreeLight } from "react-icons/pi";
import { BsCart4 } from "react-icons/bs";
import { MdAddTask } from "react-icons/md";
import { RiSettings4Line } from "react-icons/ri";
import { TbReportAnalytics } from "react-icons/tb";
import axios from "axios";

const url = Config.G_PERFORM_LINK;
const token = sessionStorage.getItem("admin_tk");

const menu = [
  {
    id: 0,
    name: "Dashboard",
    route: "/dashboard",
    icon: <TbLayoutDashboard size={25} className="text-white" />,
  },
  {
    id: 1,
    name: "Controleur",
    route: "/dashboard/controleur",
    icon: <RiUserSmileLine size={25} className="text-white" />,
  },
  {
    id: 2,
    name: "Collecteur",
    route: "/dashboard/colecteur",
    icon: <LuUsers2 size={25} className="text-white" />,
  },
  {
    id: 3,
    name: "Desistement",
    route: "/dashboard/desistement",
    icon: <MdOutlineRemoveShoppingCart size={25} className="text-white" />,
  },
  {
    id: 4,
    name: "Carnet",
    route: "/dashboard/carnet",
    icon: <PiCardsThreeLight size={25} className="text-white" />,
  },
  {
    id: 5,
    name: "Boutique",
    route: "/dashboard/store",
    icon: <BsCart4 size={25} className="text-white" />,
  },
  {
    id: 6,
    name: "Gestion des tâches",
    route: "/dashboard/tasks",
    icon: <MdAddTask size={25} className="text-white" />,
  },
  {
    id: 7,
    name: "Rapports",
    route: "/dashboard/raport",
    icon: <TbReportAnalytics size={25} className="text-white" />,
  },
  {
    id: 8,
    name: "Paramètre",
    route: "/dashboard/settings",
    icon: <RiSettings4Line size={25} className="text-white" />,
  },
];

function Navigation() {
  const [select, setSelect] = useState(0);
  const [autoRisation, setAutorisation] = useState({});

  let config = {
    method: "get",
    maxBodyLength: Infinity,
    url: `${url}/v1/admin/connected`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  useEffect(() => {
    const fetchAdminConnect = async () => {
      await axios
        .request(config)
        .then((response) => {
          setAutorisation(response.data.data);
        })
        .catch((error) => {
          console.log(error);
        });
    };
    fetchAdminConnect();
  }, []);

  return (
    <>
      <div className="flex flex-row w-[100%]">
        {/* Big Screen Menu */}
        <div className="max-sm:hidden w-[18%] max-sm:w-[0px] px-4 bg-[#0f0f0f] max-sm:h-0 max-sm:invisible h-screen">
          <nav className="my-20">
            {menu.map((item) => {
              return (
                <Link
                  className={
                    item.id === 0
                      ? autoRisation.dashboard_auto == 1
                        ? "hidden"
                        : "visible"
                      : item.id === 1
                      ? autoRisation.controleur_auto == 1
                        ? "hidden"
                        : "visible"
                      : item.id === 2
                      ? autoRisation.collecteur_auto == 1
                        ? "hidden"
                        : "visible"
                      : item.id === 3
                      ? autoRisation.desistement_auto == 1
                        ? "hidden"
                        : "visible"
                      : item.id === 4
                      ? autoRisation.carnet_auto == 1
                        ? "hidden"
                        : "visible"
                      : item.id === 5
                      ? autoRisation.boutique_auto == 1
                        ? "hidden"
                        : "visible"
                      : item.id === 6
                      ? autoRisation.tasks_auto == 1
                        ? "hidden"
                        : "visible"
                      : item.id === 7
                      ? autoRisation.repport_auto == 1
                        ? "hidden"
                        : "visible"
                      : item.id === 8 && autoRisation.settings_auto == 1
                      ? "hidden"
                      : "visible"
                  }
                  key={item.id}
                  to={item.route}
                  onClick={() => {
                    setSelect(item.id);
                  }}
                >
                  <div
                    className={
                      select == item.id
                        ? "bg-[#ADADAD24] flex flex-row p-3 rounded-md max-sm:w-0 max-sm:h-0"
                        : "flex flex-row hover:bg-[#ADADAD24] p-2 rounded-md max-sm:w-0 max-sm:h-0"
                    }
                  >
                    <div>{item.icon}</div>
                    <p className="z-1 text-white text-base ml-3">{item.name}</p>
                  </div>
                </Link>
              );
            })}
          </nav>
        </div>

        {/* Render */}
        <div className="w-full max-sm:left-0 bg-black max-sm:w-[100%]">
          <Header />
          <Outlet />
        </div>
      </div>

      {/* Mobile Menu */}
      <div className="bg-[#0f0f0f] fixed w-full h-[70px] left-0 right-0 bottom-0 justify-center items-center max-sm:visible md:hidden xl:hidden 2xl:hidden lg:hidden">
        <nav className="w-full justify-around items-center flex flex-row mt-1.5">
          {menu.map((item) => {
            return (
              <Link
                key={item.id}
                to={item.route}
                onClick={() => setSelect(item.id)}
                className={
                  item.id === 0
                    ? autoRisation.dashboard_auto == 1
                      ? "hidden"
                      : "visible"
                    : item.id === 1
                    ? autoRisation.controleur_auto == 1
                      ? "hidden"
                      : "visible"
                    : item.id === 2
                    ? autoRisation.collecteur_auto == 1
                      ? "hidden"
                      : "visible"
                    : item.id === 3
                    ? autoRisation.desistement_auto == 1
                      ? "hidden"
                      : "visible"
                    : item.id === 4
                    ? autoRisation.carnet_auto == 1
                      ? "hidden"
                      : "visible"
                    : item.id === 5
                    ? autoRisation.boutique_auto == 1
                      ? "hidden"
                      : "visible"
                    : item.id === 6
                    ? autoRisation.tasks_auto == 1
                      ? "hidden"
                      : "visible"
                    : item.id === 7
                    ? autoRisation.repport_auto == 1
                      ? "hidden"
                      : "visible"
                    : item.id === 8 && autoRisation.settings_auto == 1
                    ? "hidden"
                    : "visible"
                }
              >
                <button
                  className={
                    select === item.id
                      ? "bg-[#ADADAD36] w-[55px] h-[55px] flex items-center rounded-md justify-center"
                      : ""
                  }
                >
                  <div>{item.icon}</div>
                </button>
              </Link>
            );
          })}
        </nav>
      </div>
    </>
  );
}

export default Navigation;
