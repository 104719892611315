import React from "react";

function Notification() {
  return (
    <div className="w-full h-screen flex items-center justify-center">
      <p className="text-white font-bold text-lg">Notification</p>
    </div>
  );
}

export default Notification;
