import React from "react";
import NumberCol from "../../components/dashboard/Statistiques";

function Home() {
  return (
    <div className="px-3 overflow-hidden h-screen">
     <NumberCol />
    </div>
  );
}

export default Home;
