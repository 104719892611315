import React from "react";
import bbg from "../assets/bbg.jpg";

export default function AlternativeHome() {
  return (
    <div
      style={{ backgroundImage: `url(${bbg})` }}
      className="bg-black flex bg-contain flex-col h-screen items-center justify-center"
    >
      <div className="w-[300px] h-[200px] bg-slate-200 rounded-xl flex justify-center items-center">
        <p className="text-black text-xl font-bold text-center">
          Vous n'êtes pas authoriser à consulter cette page !
        </p>
      </div>
    </div>
  );
}
