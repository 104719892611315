import React, { useState } from "react";
import Logo from "../../assets/g-vendor/g-vendor.png";
import { Link } from "react-router-dom";
import { FaUserCircle } from "react-icons/fa";
import { IoNotificationsOutline } from "react-icons/io5";
import { AiOutlineUserSwitch } from "react-icons/ai";
import { CiLogout } from "react-icons/ci";
import { LuDot } from "react-icons/lu";

function HeaderLgg() {
  const [showModal, setShowModal] = useState(false);
  const [initialAdmin, setInitialAdmin] = useState(1);

  return (
    <>
      <div className="w-full max-sm:w-full h-[55px] bg-[#0f0f0f] p-1 fixed justify-between px-5 max-sm:px-1 flex items-center top-0 left-0 right-0">
        <img
          className="w-[95px] h-[50px] max-sm:w-[80px] mt-2 max-sm:h-[25px] rounded-md"
          src={Logo}
        />

        {/* Name */}
        <p className="text-white text-xl font-bold ">G-Vendor Admin</p>

        <div className="max-sm:w-[100px] w-[150px] flex items-center max-sm:items-end justify-around max-sm:justify-end max-sm:px-3">
          {/* Notification */}
          {/* <Link to="/dashboard/notification" className="ml-2">
            <div className="absolute top-3 ml-3 w-[15px] h-[15px] rounded-full border-[0.5] bg-red-600 flex items-center justify-center border-white p-1 z-10">
              <p className="text-white text-xs">5</p>
            </div>
            <IoNotificationsOutline
              size={25}
              color="#fff"
              className="rotation-10"
            />
          </Link> */}

          {/* Switch Profil */}
          <div
            title="Retours vers G-Performance"
            className="hover:bg-teal-950 w-10 cursor-pointer rounded-md p-1 items-center justify-center"
          >
            <button
              onClick={() => {
                const switchToLgg = sessionStorage.setItem("admin_type", 0);
                initialAdmin === 1 && setInitialAdmin(switchToLgg);
                window.location.replace("/dashboard")
              }}
            >
              <AiOutlineUserSwitch size={25} color="white" />
            </button>
            <LuDot size={25} className="absolute top-1 ml-3 text-green-500" />
          </div>

          {/* Profile */}
          <Link to="/dashboard/profil" className="max-sm:ml-6 max-sm-mr-4">
            <FaUserCircle size={30} color="#fff" />
          </Link>

          {/* Logout */}
          {/* <button
            className="flex p-1 justify-center items-center mx-2"
            onClick={() => setShowModal(true)}
          >
            <CiLogout color="white" />
          </button> */}
        </div>
      </div>

      {/* LOgPop */}
      <div
        className={
          !showModal
            ? "hidden"
            : "flex w-8/12 absolute bg-black z-10 bg-transparent h-full justify-center items-center"
        }
      >
        <div
          className={
            !showModal
              ? "hidden"
              : "absolute w-[340px] h-[240px] flex flex-col max-sm:w-full rounded-xl justify-center bg-gray-900 top-[25%]"
          }
        >
          <div className="flex absolute top-4 items-start justify-center w-full">
            <div className="p-3 rounded-full bg-[#F1000029]">
              <CiLogout size={30} color="red" />
            </div>
          </div>

          {/* Title */}
          <div className="mb-[50px]">
            <p className="text-white font-bold text-xl text-center">
              Etes-vous sûr de vouloir vous déconnecter ?
            </p>
          </div>

          <div className="px-[10%] w-full absolute flex justify-between bottom-5">
            {/* yes  */}
            <button
              onClick={() => {
                sessionStorage.removeItem("admin_tk");
                sessionStorage.removeItem("gestoken");

                setTimeout(() => {
                  window.location.replace("/");
                }, 1500);
              }}
              className="w-full h-10 bg-red-600 rounded-md hover:bg-slate-600 mx-2"
            >
              <p className="text-white font-medium text-base">Oui</p>
            </button>

            {/* Not  */}
            <button
              onClick={() => {
                setShowModal(false);
              }}
              className="w-full h-10 bg-blue-600 rounded-md hover:bg-slate-600 mx-2"
            >
              <p className="text-white font-medium text-base">Non</p>
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default HeaderLgg;
