import React from "react";
import Carnet from "../../comonents/carnets/CarnetsListLgg";

export default function CarnetLgg() {
  return (
    <div className="overflow-hidden h-screen">
      <Carnet />
    </div>
  );
}
