import React from "react";
import ColecteurListes from "../../components/colecteur/ColecteurList";

function Colecteur() {
  return (
    <div className="px-3 overflow-hidden h-screen">
      <ColecteurListes />
    </div>
  );
}

export default Colecteur;
