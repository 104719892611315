import React, { useEffect, useState } from "react";
import Load from "../../../assets/rff.svg";
import Nothing from "../../../assets/svg/order.svg";
import { GiShoppingCart } from "react-icons/gi";
import Config from "../../config/Links";
import axios from "axios";

const menu = [
  { id: 0, name: "Tous", icon: "" },
  { id: 1, name: "Livré", icon: "" },
  { id: 2, name: "En cours", icon: "" },
  { id: 3, name: "Terminé & Non Livré", icon: "" },
];

export default function OrderListesLgg() {
  const [select, setSelect] = useState(0);
  const [OrderStatus, setOrderStatus] = useState("");
  const [OrderListe, setOrderListe] = useState([]);
  const [notFound, setNotFound] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchAllOrders = async () => {
      const token = sessionStorage.getItem("admin_tk");
      let config = {
        method: "get",
        maxBodyLength: Infinity,
        url: `${Config.G_PERFORM_LINK}/v2/get/all/orders?query=${OrderStatus}`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      await axios
        .request(config)
        .then((response) => {
          setLoading(false);
          setNotFound(false);
          setOrderListe(response.data.orders);
        })
        .catch((error) => {
          if (error.response && error.response.status === 404) {
            setLoading(false);
            setNotFound(true);
          }
          console.log(error);
        });
    };
    fetchAllOrders();
  }, [OrderStatus]);

  useEffect(() => {
    const statusMapping = {
      0: "",
      1: "Livré",
      2: "En cours",
      3: "Terminé & Non Livré",
    };
    setOrderStatus(statusMapping[select] || "");
  }, [select]);

  // Function to get the number of orders by status
  const getOrderCount = (status) => {
    return OrderListe.filter((order) => order.status === status).length;
  };

  // Total number of orders
  const totalOrdersCount = OrderListe.length;

  return (
    <>
      <div className="py-[60px] w-full flex-col max-sm:px-2">
        {/* Navigations */}
        <div>
          <div className="flex bg-[#ffffff14] p-2 rounded-full justify-center w-[30%]">
            {menu.map((item) => (
              <button
                key={item.id}
                onClick={() => setSelect(item.id)}
                className={
                  select === item.id
                    ? "p-3 bg-blue-500 w-[auto] flex items-center rounded-2xl"
                    : "p-3 bg-[transparent] flex flex-col"
                }
              >
                {/*  */}
                <p className="text-white">{item.name}</p>

                {/* Number */}
                {select === item.id && (
                  <div className="bg-[#ff0d0034] rounded-full m-1 flex items-center justify-center">
                    <p className="text-red-500 ml-2 text-center font-bold">
                      {item.id === 0
                        ? totalOrdersCount
                        : getOrderCount(item.name)}
                    </p>
                  </div>
                )}
              </button>
            ))}
          </div>
        </div>
        {/* Body */}

        {/* Loading state */}
        {loading ? (
          <div className="flex flex-col h-[80vh] items-center justify-center">
            <img src={Load} className="w-12 h-12" alt="Loading" />
          </div>
        ) : notFound ? (
          <div className="flex flex-col items-center justify-center">
            <img src={Nothing} className="w-32 h-32" alt="Not found" />
            <p className="text-white">
              {select === 0
                ? "Aucune commandes trouvée !"
                : select === 1
                ? "Aucune commandes livrée !"
                : select === 2
                ? "Pas de commande en cours.. !"
                : select === 3 && "Aucune commandes terminée !"}
            </p>
          </div>
        ) : (
          (select === 0 && OrderListe.length > 0 && (
            <div className="w-full h-[auto] overflow-auto scrollbar-hide flex flex-wrap">
              {OrderListe.map((item, index) => (
                <button
                  key={index}
                  className="bg-[#ffffff24] hover:bg-blue-600 w-[15%] flex items-center m-3 justify-start my-1.5 p-5 rounded"
                >
                  <GiShoppingCart size={25} color="white" />

                  <div className="flex flex-col items-start ml-3">
                    <p className="text-white"> N* :{item.numero_compte}</p>
                    <p className="text-white">{item.product_name}</p>
                    <p className="text-green-500">
                      Prix :{" "}
                      {(
                        parseFloat(item.prix_vente).toFixed(0) * 1
                      ).toLocaleString("fr-FR") +
                        " " +
                        "F"}
                    </p>
                  </div>
                </button>
              ))}
            </div>
          )) ||
          (select === 1 && OrderStatus === "Livré" && (
            <div className="w-full h-[auto] overflow-auto scrollbar-hide flex flex-wrap">
              {OrderListe.map((item, index) => (
                <button
                  key={index}
                  className="bg-[#ffffff24] hover:bg-blue-600 w-[15%] flex items-center m-3 justify-start my-1.5 p-5 rounded"
                >
                  <GiShoppingCart size={25} color="white" />

                  <div className="flex flex-col items-start ml-3">
                    <p className="text-white"> N* :{item.numero_compte}</p>
                    <p className="text-white">{item.product_name}</p>
                    <p className="text-green-500">
                      Prix :{" "}
                      {(
                        parseFloat(item.prix_vente).toFixed(0) * 1
                      ).toLocaleString("fr-FR") +
                        " " +
                        "F"}
                    </p>
                  </div>
                </button>
              ))}
            </div>
          )) ||
          (select === 2 && OrderStatus === "En cours" && (
            <div className="w-full h-[auto] overflow-auto scrollbar-hide flex flex-wrap">
              {OrderListe.map((item, index) => (
                <button
                  key={index}
                  className="bg-[#ffffff24] hover:bg-blue-600 w-[15%] flex items-center m-3 justify-start my-1.5 p-5 rounded"
                >
                  <GiShoppingCart size={25} color="white" />

                  <div className="flex flex-col items-start ml-3">
                    <p className="text-white"> N* :{item.numero_compte}</p>
                    <p className="text-white">{item.product_name}</p>
                    <p className="text-green-500">
                      Prix :{" "}
                      {(
                        parseFloat(item.prix_vente).toFixed(0) * 1
                      ).toLocaleString("fr-FR") +
                        " " +
                        "F"}
                    </p>
                  </div>
                </button>
              ))}
            </div>
          )) ||
          (select === 3 && OrderStatus === "Terminé & Non Livré" && (
            <div className="w-full h-[auto] overflow-auto scrollbar-hide flex flex-wrap">
              {OrderListe.map((item, index) => (
                <button
                  key={index}
                  className="bg-[#ffffff24] hover:bg-blue-600 w-[15%] flex items-center m-3 justify-start my-1.5 p-5 rounded"
                >
                  <GiShoppingCart size={25} color="white" />

                  <div className="flex flex-col items-start ml-3">
                    <p className="text-white"> N* :{item.numero_compte}</p>
                    <p className="text-white">{item.product_name}</p>
                    <p className="text-green-500">
                      Prix :{" "}
                      {(
                        parseFloat(item.prix_vente).toFixed(0) * 1
                      ).toLocaleString("fr-FR") +
                        " " +
                        "F"}
                    </p>
                  </div>
                </button>
              ))}
            </div>
          ))
        )}
      </div>

      {/* Order Details */}
    </>
  );
}
