import React from "react";
import Order from "../../comonents/orders/OrdersListeLgg";

export default function OrdersLgg() {
  return (
    <div className="overflow-hidden h-screen">
      <Order />
    </div>
  );
}
