import React from "react";

function Profil() {
  return (
    <div className="w-full h-screen flex items-center justify-center">
      {/* First */}
      <div></div>

      {/* Sec */}
    </div>
  );
}

export default Profil;
