import React from "react";
import Vendeur from "../../comonents/vendeurs/VendeursListesLgg"

export default function VendeursLgg() {
  return (
    <div className="overflow-hidden h-screen">
      <Vendeur />
    </div>
  );
}
